import {useNavigation, useTranslate} from "@refinedev/core";

import React from "react";
import {List, useDataGrid,} from "@refinedev/mui";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {DateField} from "../../../components/fields/date";
import {SearchField} from "../../../components/custom/SearchField";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";
import {Chip, Stack, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import {IDevice} from "../../../rest-data-provider/bfaTypes";

export const DeviceList = () => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const { dataGridProps, search } = useDataGrid(
        {
            onSearch: BfARefineUtils.dataGridSearch,
            pagination: {pageSize: 100}
        });

    const columns = React.useMemo<GridColDef<IDevice>[]>(
        () => [
            {
                field: "id",
                headerName: translate('devices.fields.id'),
                type: "number",
                minWidth: 50,
            },
            {
                field: "name",
                align: 'left',
                headerName: translate('devices.fields.name'),
                flex: 1,
                minWidth: 200,
            },
            {
                field: "sorting",
                align: 'center',
                headerName: translate('devices.fields.sorting'),
                minWidth: 100,
            },
            {
                field: "defaultProtectionClass",
                align: 'center',
                headerName: translate('devices.fields.defaultProtectionClass'),
                minWidth: 100,
            },
            {
                field: "categories",
                align: 'center',
                headerName: translate('devices.fields.categories'),
                minWidth: 200,
                maxWidth: 300,
                flex: 1,
                renderCell: params => {
                    return (
                        <Stack style={{margin: '5px'}} direction={'row'} gap={'3px'} useFlexGap flexWrap="wrap">
                            {params.row.categories.length > 0 && params.row.categories.map(category => {
                                return (
                                    <Chip style={{backgroundColor: uniqolor(category.name, {lightness: 85}).color}} size={'medium'} key={category.id} label={category.name}></Chip>
                                )
                            })}
                            {params.row.categories.length === 0 && (
                                <Typography color={'grey'}>keine</Typography>
                            )}
                        </Stack>
                    )
                }
            },
            {
                field: "image",
                align: 'center',
                headerName: translate("devices.fields.image"),
                minWidth: 100,
                renderCell: function render({ value }) {
                    return (
                        <img
                            src={value}
                            style={{ height: "50px", maxWidth: "100px" }}
                        />
                    );
                },
            },
            {
                field: "createdAt",
                // flex: 1,
                headerName: translate("devices.fields.createdAt"),
                minWidth: 200,
                maxWidth: 300,
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "updatedAt",
                // flex: 1,
                headerName: translate("devices.fields.updatedAt"),
                minWidth: 200,
                maxWidth: 300,
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            }
        ],
        [translate],
    );

    return (
        <BfADataGrid onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
            show('devices', params.id)
        }} columns={columns} />
    );
};
