import { Create } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

import {useState} from "react";
import {useTranslate} from "@refinedev/core";
import {IBfABaseType, ICustomerType} from "../../../rest-data-provider/bfaTypes";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";

export const MeasuringDeviceCreate = () => {

    const translate = useTranslate();

    const {
        saveButtonProps,
        refineCore: { queryResult , formLoading},
        register,
        control,
        formState: { errors },
        setError
    } = useForm<IBfABaseType>({
        refineCoreProps: {
            onMutationError: error => {
                BfARefineUtils.extractServerSideErrors(error, setError);
            },
        }
    });


  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps} >
      <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
      >
        <TextField
          {...register("name")}
          error={!!(errors as any)?.name}
          helperText={(errors as any)?.name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate('measuringdevices.fields.name')}
          name="name"
        />
        <TextField
            {...register("serialNumber")}
            error={!!(errors as any)?.serialNumber}
            helperText={(errors as any)?.serialNumber?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate('measuringdevices.fields.serialNumber')}
            name="serialNumber"
        />
      </Box>
    </Create>
  );
};
