import {useNavigation, useTranslate} from "@refinedev/core";

import React from "react";
import {useDataGrid,} from "@refinedev/mui";
import {GridColDef} from "@mui/x-data-grid";
import {DateField} from "../../../components/fields/date";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";

export const BadgesList = () => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const { dataGridProps, search } = useDataGrid(
        {
            onSearch: BfARefineUtils.dataGridSearch,
            pagination: {pageSize: 100}
        });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("badges.fields.id"),
                type: "number",
                minWidth: 50,
                align: 'center',
                headerAlign: 'center'
            },
            {
                field: "year",
                type: "string",
                headerName: translate("badges.fields.year"),
                flex: 1,
                minWidth: 300,
            },
            {
                field: "image",
                align: 'center',
                headerName: translate("badges.fields.image"),
                minWidth: 100,
                renderCell: function render({ value }) {
                    return (
                        <img
                            src={value}
                            style={{ height: "50px", maxWidth: "100px" }}
                        />
                    );
                },
            },
            {
                field: "createdAt",
                // flex: 1,
                headerName: translate("badges.fields.createdAt"),
                minWidth: 150,
                align: 'center',
                headerAlign: 'center',
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "updatedAt",
                // flex: 1,
                headerName: translate("badges.fields.updatedAt"),
                minWidth: 150,
                align: 'center',
                headerAlign: 'center',
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            }
        ],
        [translate],
    );

    return (
        <BfADataGrid showSearch={false} onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
            show('badges', params.id)
        }} columns={columns} />
    );
};
