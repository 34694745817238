import {useTranslate} from "@refinedev/core";
import {useForm} from "@refinedev/react-hook-form";
import {IBfABaseType} from "../../../rest-data-provider/bfaTypes";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {Create} from "@refinedev/mui";
import {Box, TextField} from "@mui/material";

export const DeviceCategoryCreate = () => {

    const translate = useTranslate();

    const {
        saveButtonProps,
        refineCore: { queryResult , formLoading},
        register,
        control,
        formState: { errors },
        setError
    } = useForm<IBfABaseType>({
        refineCoreProps: {
            onMutationError: error => {
                BfARefineUtils.extractServerSideErrors(error, setError);
            },
        }
    });
    
    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("name")}
                    error={!!(errors as any)?.name}
                    helperText={(errors as any)?.name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate('measuringdevices.fields.name')}
                    name="name"
                />
            </Box>
        </Create>
    );
};