import React, {useEffect, useLayoutEffect} from "react";
import {Alert, Box, Button, Checkbox, Drawer, FormControl, FormGroup, TextField} from "@mui/material";
import {UseModalFormReturnType} from "@refinedev/react-hook-form";
import {HttpError, useList, useTranslate} from "@refinedev/core";
import {Create, Edit} from "@refinedev/mui";
import {CheckBoxGroup} from "../../../components/custom/CheckBoxGroup";
import {ICustomerType, IFacility} from "../../../rest-data-provider/bfaTypes";
import {Controller} from "react-hook-form";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";


interface FacilityEditDrawerProps {
    customer: ICustomerType | undefined
}
type CombinedProps = UseModalFormReturnType<IFacility, HttpError, IFacility> & FacilityEditDrawerProps;
export const FacilitySaveDrawer : React.FC<
    CombinedProps
> = ({ saveButtonProps,
       refineCore: { queryResult, mutationResult, id},
       modal: { visible, close },
       register,
       control,
       formState: { errors,},
       setError,
       clearErrors,
       getValues,
       reset, customer} ) => {


    const translate = useTranslate();
    useLayoutEffect(() => {
        if(mutationResult.error) {
            for (const violation of mutationResult.error.response.data.violations) {
                console.log(violation)
                setError(violation.fieldName,{message: violation.message})
            }
        }
    }, [mutationResult.error])
    useLayoutEffect(() => {
        if(!visible) {
            clearErrors()
        } else {
            reset({
                id: queryResult!.data?.data.id,
                name: queryResult!.data?.data.name,
                city: queryResult!.data?.data.city,
                street: queryResult!.data?.data.street,
                houseNumber: queryResult!.data?.data.houseNumber,
                zipCode: queryResult!.data?.data.zipCode,
                facilityTypes: queryResult!.data?.data.facilityTypes
            });
        }
    },[visible])
    const { data: allFacilityTypes, isLoading, isError } = useList<IFacility, HttpError>({
        resource: "facilities/types",
    });


    const formContent = (
        <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            autoComplete="off"
        >
            {id && (
                <TextField
                    {...register("id", {
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth

                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate('facilities.fields.id')}
                    name="id"
                    disabled
                />
            )}

            <TextField
                {...register("name", {
                })}
                error={!!(errors as any)?.name}
                helperText={(errors as any)?.name?.message}
                margin="normal"
                fullWidth
                placeholder={customer?.name}
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('facilities.fields.name')}
                name="name"
            />
            <TextField
                {...register("street", {
                })}
                error={!!(errors as any)?.street}
                helperText={(errors as any)?.street?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('facilities.fields.street')}
                name="street"
            />
            <TextField
                {...register("houseNumber", {
                })}
                error={!!(errors as any)?.houseNumber}
                helperText={(errors as any)?.houseNumber?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('facilities.fields.houseNumber')}
                name="houseNumber"
            />
            <TextField
                {...register("zipCode", {
                })}
                error={!!(errors as any)?.zipCode}
                helperText={(errors as any)?.zipCode?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('facilities.fields.zipcode')}
                name="zipCode"
            />
            <TextField
                {...register("city", {
                })}
                error={!!(errors as any)?.city}
                helperText={(errors as any)?.city?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('facilities.fields.city')}
                name="city"
            />
            <Controller name={"facilityTypes"}  control={control}
                render={({
                             field: { onChange, onBlur, value, name, ref },
                             fieldState: { invalid, isTouched, isDirty, error },
                             formState,
                         }) => {
                    return (
                        <CheckBoxGroup onChange={selectedOptions => {
                            onChange(selectedOptions);
                        }} title={translate('facilities.fields.types')} options={allFacilityTypes?.data}
                                       selectedOptions={value}></CheckBoxGroup>
                    )
                }} />
        </Box>)

    return(
        <Drawer
            open={visible}
            onClose={() => {
                reset({
                    id: queryResult!.data?.data.id,
                    name: queryResult!.data?.data.name,
                    city: queryResult!.data?.data.city,
                    street: queryResult!.data?.data.street,
                    houseNumber: queryResult!.data?.data.houseNumber,
                    zipCode: queryResult!.data?.data.zipCode,
                    facilityTypes: queryResult!.data?.data.facilityTypes
                });
                close()
            }}
            anchor="right"
            PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
        >

            {id && (
                <Edit wrapperProps={{style: {overflowY: 'scroll'}}}  title={translate('facilities.titles.edit')} deleteButtonProps={{
                    errorNotification: error => BfARefineUtils.generateDeleteErrorNotification('Einrichtung'),
                    successNotification: data => BfARefineUtils.generateDeleteSuccesNotification('Einrichtung'),
                    resource:`customers/${customer?.id}/facilities`,
                    onSuccess: value => {
                        setTimeout(() => {
                            close();
                        }, 500)
                    }
                }} recordItemId={id}  breadcrumb={false} goBack={false} canDelete={true} resource={`customers/${customer?.id}/facilities`} saveButtonProps={saveButtonProps}>
                    <div style={{fontSize: 'larger', marginBottom: '15px'}}>
                        <label style={{fontWeight: 'bold'}}>Kunde: </label><label> {customer?.name} </label>
                    </div>

                    {formContent}
                </Edit>
            )}
            {!id && (
                <Create wrapperProps={{style: {overflowY: 'scroll'}}} title={translate('facilities.titles.create')} breadcrumb={false} goBack={false} resource={`customers/${customer?.id}/facilities`}  saveButtonProps={saveButtonProps}>
                    {formContent}
                </Create>
            )}
        </Drawer>
    )
}