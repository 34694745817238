import {CrudFilter, useApiUrl, useInvalidate, useList, useNavigation, useTranslate} from "@refinedev/core";

import React, {useEffect, useState} from "react";
import {List, useDataGrid,} from "@refinedev/mui";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {DateField} from "../../../components/fields/date";
import {SearchField} from "../../../components/custom/SearchField";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";
import {Button, Chip, Grid, IconButton, Stack, TextField, ToggleButtonGroup, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import {IDevice, IDevicesPerEmployee, IEmployee, IInspection} from "../../../rest-data-provider/bfaTypes";
import {LuExternalLink, LuFileCheck, LuFileClock} from "react-icons/lu";
import {Autocomplete, ToggleButton} from "@mui/lab";
import {Download} from "@mui/icons-material";
import {SiMicrosoftexcel} from "react-icons/si";
import {FaBusinessTime} from "react-icons/fa";
import {MdAccessTime} from "react-icons/md";
import {axiosInstance} from "../../../rest-data-provider/utils";
import { saveAs } from 'file-saver';
import SelectInput from "@mui/material/Select/SelectInput";
import {TbMailExclamation} from "react-icons/tb";
import {SendErrorReportDialog} from "./sendErrorReportDialog";

export const InspectionsList = () => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const [inspectionFilter, setInspectionFilter] = useState<string>('all');
    const [employeeFilter, setEmployeeFilter] = useState<number|null>(null)
    const [errorDialogInspectionId, setErrorDialogInspectionId] = useState<number|null>(null);


    const generateInspectionFilter  = (filter: string) : CrudFilter => {
        return {
            field: "f",
            operator: "eq",
            value: filter,
        }
    }

    const generateEmployeeFilter = (filter: number): CrudFilter => {
        return {
            field: "e",
            operator: "eq",
            value: filter,
        }
    }

    const {isLoading: employeesLoading, data: employeeData} = useList<IEmployee>({
        resource: 'employees',
        pagination: {pageSize: 10000}
    });
    const employees = employeeData?.data



    useEffect(() => {
        setSorters([
            {
                field:'begin',
                order: "desc"
            }
        ])
    }, [])

    useEffect(() => {

        let filter = filters
        if(inspectionFilter) {
            filter = [generateInspectionFilter(inspectionFilter), ...filter];
        }
        if(employeeFilter) {
            filter = [generateEmployeeFilter(employeeFilter), ...filter];
        } else {
            filter = [generateInspectionFilter(inspectionFilter)];
        }
        setFilters(filter);

    }, [inspectionFilter, employeeFilter])

    const { dataGridProps, search, setFilters, filters, setSorters   } = useDataGrid(
        {
            onSearch: (data) => {
                return (BfARefineUtils.dataGridSearch(data as string)).concat(generateInspectionFilter(inspectionFilter));
            },
            pagination: {pageSize: 100}
        });

    const invalidate = useInvalidate();

    const findDeviceCountForEmployee = (employeeId : number, devicesPerEmployee: IDevicesPerEmployee[]) : number => {

        for (const iDevicesPerEmployee of devicesPerEmployee) {
            if(iDevicesPerEmployee.employeeId == employeeId) {
                return iDevicesPerEmployee.deviceCount;
            }
        }
        return 0;
    }



    const columns = React.useMemo<GridColDef<IInspection>[]>(
        () => [
            {
                field: "id",
                headerName: translate('inspections.fields.id'),
                type: "number",
                headerAlign: 'center',
                minWidth: 50,
                renderCell: function render({ value, row}) {
                    return (
                        <>
                            {value}
                            <IconButton size={'medium'} aria-label="delete" color="primary" onClick={(evt) => {
                                const win = window.open(`/inspections/show/${value}`, '_blank');
                                if(win) {
                                    win.focus();
                                }
                                evt.stopPropagation();
                            }}>
                                <LuExternalLink size={15} />
                            </IconButton>
                        </>
                    );
                }
            },
            {
                field: "customer",
                align: 'left',
                headerName: translate('inspections.fields.customer'),
                flex: 1,
                minWidth: 300,
                valueGetter: params => {
                    return params.value?.name ? params.value.name : '-'
                }
            },
            {
                field: "facility",
                align: 'left',
                headerName: translate('inspections.fields.facility'),
                flex: 1,
                minWidth: 300,
                valueGetter: params => {
                    let facilityType = null
                    if(params.row.facility?.facilityTypes?.length > 0) {
                        facilityType = params.row.facility.facilityTypes[0].name
                    }
                    if (facilityType) {
                        return params.value?.name ? `${params.value.name} (${facilityType})` : '-'
                    }
                    return params.value?.name ? `${params.value.name}` : '-'
                }
            },

            {
                field: "inspectors",
                align: 'left',
                headerName: translate('inspections.fields.inspectors'),
                minWidth: 200,
                sortable: false,
                // valueGetter: params => {
                //     params.value.inspectors.map()
                //     return params.value ? params.value.firstName + " " + params.value.lastName : ''
                // }
                renderCell: function render({ value, row}) {
                    return (
                        <div>
                            <ul style={{paddingLeft: 15}}>
                            {row.inspectors?.map((value1, index, array) => {
                                if (value1) {

                                    const crown = value1.id === row.leader?.id ? '👑' : '';
                                    const deviceCount = findDeviceCountForEmployee(value1.id, row.devicesPerEmployee);
                                    return (
                                        <li>
                                            {`${value1?.firstName} ${value1?.lastName} (${deviceCount}) ${crown}`}
                                        </li>
                                    )
                                } else {
                                    return <></>
                                }

                            })}
                            </ul>
                        </div>
                    );
                },
            },
            {
                field: "itemCount",
                // flex: 1,
                headerName: translate("inspections.fields.itemCount"),
                minWidth: 70,
                maxWidth: 70,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: "begin",
                // flex: 1,
                headerName: translate("inspections.fields.begin"),
                minWidth: 100,
                maxWidth: 100,
                align: 'center',
                headerAlign: 'center',
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "end",
                // flex: 1,
                headerName: translate("inspections.fields.end"),
                minWidth: 100,
                maxWidth: 100,
                align: 'center',
                headerAlign: 'center',
                renderCell: function render({ value }) {
                    if(value) {
                        return <DateField value={value} />;
                    }
                    return (<label style={{color: 'grey'}}>Läuft noch</label>)
                },
            },
            {
                field: "didCreateReport",
                headerAlign: 'center',
                align: 'center',
                headerName: translate("inspections.fields.status"),
                minWidth: 70,
                maxWidth: 70,
                sortable: false,
                renderCell: function render({ value, row }) {
                    return (
                        <>
                            {value && row.end && (
                                <LuFileCheck size={22} style={{color: 'green'}}></LuFileCheck>
                            )}
                            {!value && row.end && (
                                <LuFileClock size={22} style={{color: '#b58e22'}}></LuFileClock>
                            )}
                            {!row.end && (
                                <MdAccessTime size={22} style={{color: '#9a0000'}}></MdAccessTime>
                            )}
                        </>
                    );
                },
            },
            {
                field: "action",
                // flex: 1,
                headerName: translate("inspections.fields.actions"),
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                minWidth: 180,
                maxWidth: 180,
                renderCell: function render({ value, row }) {
                    return (
                        <>
                            <Grid container>
                                <Grid item xs={12} style={{display: 'flex', placeContent: 'center',  placeItems: 'center'}}>
                                    <Button variant={'outlined'} size={'small'}  color={'success'} onClick={(evt) => {
                                        evt.stopPropagation();
                                        BfARefineUtils.downloadReport(row, () => {
                                            invalidate({
                                                resource: 'inspections',
                                                invalidates: ["resourceAll"]
                                            })
                                        })
                                    }}>
                                        <SiMicrosoftexcel size={20} />
                                    </Button>
                                </Grid>
                                {/*<Grid item xs={6} style={{display: 'flex', placeContent: 'center',  placeItems: 'center'}}>*/}
                                {/*    <Button variant={'outlined'} size={'small'}  color={'error'} onClick={(evt) => {*/}
                                {/*        evt.stopPropagation();*/}
                                {/*        setErrorDialogInspectionId(row.id);*/}
                                {/*    }}>*/}
                                {/*        <TbMailExclamation size={20} />*/}
                                {/*    </Button>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </>
                        )
                },
            },
        ],
        [translate],
    );

    const headerComponent = (
        <>
            <Stack direction={"row"} spacing={1}>
                <ToggleButtonGroup size={"small"}
                                   value={inspectionFilter}
                                   exclusive
                    // onChange={setInspectionFilter}
                                   aria-label="text alignment"
                >
                    <ToggleButton sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                            fontWeight: 'bold',
                            color: '#3a3f60'
                        }
                    }} value="all" aria-label="left aligned" onClick={() => {
                        setInspectionFilter('all');
                    }}>
                        Alle
                    </ToggleButton>
                    <ToggleButton style={{color: 'gray'}} sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                            color: 'green !important'
                        }
                    }} value="finished" aria-label="left aligned" onClick={() => {
                        setInspectionFilter('finished');
                    }}>
                        <LuFileCheck size={22}></LuFileCheck>
                    </ToggleButton>
                    <ToggleButton style={{color: 'gray'}} sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                            color: '#b58e22 !important'
                        }
                    }}  value="report" aria-label="centered" onClick={() => {
                        setInspectionFilter('report');
                    }}>
                        <LuFileClock size={22}></LuFileClock>
                    </ToggleButton>
                    <ToggleButton style={{color: 'gray'}} sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                            color: '#9a0000 !important'
                        }
                    }}  value="start" aria-label="right aligned" onClick={() => {
                        setInspectionFilter('start');
                    }}>
                        <LuFileClock size={22} ></LuFileClock>
                    </ToggleButton>

                </ToggleButtonGroup>
                <Autocomplete
                    size={"small"}
                    disablePortal
                    id="combo-box-demo"
                    options={employees ? employees: []}
                    getOptionLabel={option => {
                        return option.firstName + " " + option.lastName;
                    }}
                    onChange={(event, value) => {
                        if(value) {
                            setEmployeeFilter(value.id);
                        } else {
                            setEmployeeFilter(null);
                        }
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Prüfer" />}
                />
            </Stack>
        </>
        );

    return (
        <>

                <SendErrorReportDialog onClose={() => {
                    setErrorDialogInspectionId(null);
                }} inspectionId={errorDialogInspectionId} type={'mail'} title={'Versand eines vorläufigen Fehlerprotokolls'}></SendErrorReportDialog>


            <BfADataGrid onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
                show('inspections', params.id)
            }} columns={columns} additionalHeaderComponents={headerComponent} />
        </>
    );
};
