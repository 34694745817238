import {Authenticated, GitHubBanner, I18nProvider, Refine} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  RefineSnackbarProvider,
  ThemedLayoutV2, useNotificationProvider,
} from "@refinedev/mui";

import {createTheme, CssBaseline, GlobalStyles} from "@mui/material";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import {
  MeasuringDeviceEdit,
  BlogPostShow, MeasuringDeviceCreate,
} from "./pages/entities/measuringdevices";

import { ForgotPassword } from "./pages/forgotPassword";
import { Login } from "./pages/login";
import { Register } from "./pages/register";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import {authProvider} from "./authProvider";
import { Header } from "./components";
import {dataProvider} from "./rest-data-provider";
import {MeasuringDeviceList} from "./pages/entities/measuringdevices";
import { MuiInferencer } from "@refinedev/inferencer/mui";
import {DeviceCreate} from "./pages/entities/devices/createEdit";
import {CustomerEdit} from "./pages/entities/customers/edit";
import {useTranslation} from "react-i18next";
import {CustomerShow} from "./pages/entities/customers/show";
import {CustomerList} from "./pages/entities/customers/list";
import {HiOutlineBuildingOffice2} from "react-icons/hi2";
import {CustomerCreate} from "./pages/entities/customers/create";
import {TfiDashboard} from "react-icons/tfi";
import {MdOutlineCategory, MdOutlineDevicesOther, MdSettings} from "react-icons/md";
import {DeviceCategoryList} from "./pages/entities/devicecategories/list";
import {DeviceCategoryShow} from "./pages/entities/devicecategories/show";
import {DeviceCategoryCreate} from "./pages/entities/devicecategories/create";
import {DeviceCategoryEdit} from "./pages/entities/devicecategories/edit";
import {DeviceList} from "./pages/entities/devices/list";
import {DeviceShow} from "./pages/entities/devices/show";
import {FaRegStickyNote} from "react-icons/fa";
import {TbListCheck} from "react-icons/tb";
import {NotesList} from "./pages/entities/notes/list";
import {NoteShow} from "./pages/entities/notes/show";
import {NoteCreateEdit} from "./pages/entities/notes/createEdit";
import {BadgesList} from "./pages/entities/badges/list";
import {BadgeShow} from "./pages/entities/badges/show";
import {BiBadgeCheck} from "react-icons/bi";
import {BadgeSave} from "./pages/entities/badges/editCreate";
import {ThemeProvider} from "@mui/material/styles";
import {InspectionsList} from "./pages/entities/inspections/list";
import {GrUserSettings, GrUserWorker} from "react-icons/gr";
import {AdminsList} from "./pages/entities/admin/list";
import {AdminsShow} from "./pages/entities/admin/show";
import {AdminCreateEdit} from "./pages/entities/admin/createEdit";
import {EmployeeList} from "./pages/entities/employees/list";
import {EmployeeShow} from "./pages/entities/employees/show";
import {EmployeeCreateEdit} from "./pages/entities/employees/createEdit";
import {InspectionShow} from "./pages/entities/inspections/show";
import {InspectionsCreateEdit} from "./pages/entities/inspections/createEdit";
import 'dayjs/locale/de'

function App() {

  const { t, i18n } = useTranslation();
  const i18nProvider: I18nProvider = {
    translate: (key, options, defaultMessage) => t(key, options, defaultMessage),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#002d64',
      },
      secondary: {
        main: '#d21e23',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <BrowserRouter>
          {/*<GitHubBanner />*/}
          <RefineKbarProvider>
            {/*<ColorModeContextProvider>*/}
              <RefineSnackbarProvider>
                <Refine
                    dataProvider={dataProvider(import.meta.env.VITE_API_BASE_URL)}
                    notificationProvider={useNotificationProvider}
                    routerProvider={routerBindings}
                    authProvider={authProvider}
                    i18nProvider={i18nProvider}
                    resources={[
                      {
                        name: "customers",
                        list: "/customers",
                        create: "/customers/create",
                        edit: "/customers/edit/:id",
                        show: "/customers/show/:id",
                        meta: {
                          canDelete: true,
                          label: 'Kunden',
                          icon: <HiOutlineBuildingOffice2></HiOutlineBuildingOffice2>
                        },
                      },
                      {
                        name: "inspections",
                        list: "/inspections",
                        // create: "/inspections/create",
                        edit: "/inspections/edit/:id",
                        show: "/inspections/show/:id",
                        meta: {
                          canDelete: true,
                          label: 'Prüfungen',
                          icon: <TbListCheck></TbListCheck>
                        },
                      },
                      {
                        name: "settings",
                        meta: {
                          label: "Einstellungen",
                          icon: <MdSettings></MdSettings>
                              }
                      },
                      {
                        name: "devices",
                        list: "/devices",
                        create: "/devices/create",
                        edit: "/devices/edit/:id",
                        show: "/devices/show/:id",
                        meta: {
                          parent: 'settings',
                          canDelete: true,
                          label: 'Betriebsmittel',
                          icon: <MdOutlineDevicesOther></MdOutlineDevicesOther>
                        },
                      },
                      {
                        name: "device-categories",
                        list: "/device-categories",
                        create: "/device-categories/create",
                        edit: "/device-categories/edit/:id",
                        show: "/device-categories/show/:id",
                        meta: {
                          parent: 'settings',
                          canDelete: true,
                          label: 'Betriebsmittelkategorien',
                          icon: <MdOutlineCategory></MdOutlineCategory>
                        },
                      },
                      {
                        name: "notes",
                        list: "/notes",
                        create: "/notes/create",
                        edit: "/notes/edit/:id",
                        show: "/notes/show/:id",
                        meta: {
                          parent: 'settings',
                          canDelete: true,
                          icon: <FaRegStickyNote></FaRegStickyNote>
                        },
                      },
                      {
                        name: "measuringdevices",
                        list: "/measuringdevices",
                        create: "/measuringdevices/create",
                        edit: "/measuringdevices/edit/:id",
                        show: "/measuringdevices/show/:id",
                        meta: {
                          parent: 'settings',
                          canDelete: true,
                          label: 'Messgeräte',
                          icon: <TfiDashboard></TfiDashboard>

                        },
                      },
                      {
                        name: "badges",
                        list: "/badges",
                        create: "/badges/create",
                        edit: "/badges/edit/:id",
                        show: "/badges/show/:id",
                        meta: {
                          parent: "settings",
                          canDelete: true,
                          label: 'Prüfplaketten',
                          icon: <BiBadgeCheck></BiBadgeCheck>
                        },
                      },
                      {
                        name: "admins",
                        list: "/admins",
                        create: "/admins/create",
                        edit: "/admins/edit/:id",
                        show: "/admins/show/:id",
                        meta: {
                          parent: 'settings',
                          canDelete: true,
                          label: 'Admins',
                          icon: <GrUserSettings></GrUserSettings>

                        },
                      },
                      {
                        name: "employees",
                        list: "/employees",
                        create: "/employees/create",
                        edit: "/employees/edit/:id",
                        show: "/employees/show/:id",
                        meta: {
                          parent: 'settings',
                          canDelete: true,
                          label: 'Mitarbeiter',
                          icon: <GrUserWorker></GrUserWorker>
                        },
                      },
                    ]}
                    options={{
                      syncWithLocation: true,
                      warnWhenUnsavedChanges: true,
                      //reactQuery: {clientConfig: {defaultOptions: {queries:{staleTime: 0, cacheTime: 0}}}}
                    }}
                >
                  <Routes>
                    <Route
                        element={
                          <Authenticated key={'main'} fallback={<CatchAllNavigate to="/login" />}>
                            <ThemedLayoutV2 Title={(props, context) => {

                              return (
                                <>
                                  {props.collapsed && (
                                      <div><img src={'/img/logo.png'} style={{width: '50px', paddingLeft: '3px'}} /></div>
                                  )}
                                  {!props.collapsed && (
                                      <div style={{display:'flex', flexDirection: 'row', placeItems: 'center', alignContent: 'center'}}>
                                        <img  src={'/img/logo.png'} style={{height: '30px', paddingLeft: '3px'}} />
                                        <label style={{ backgroundColor: '#002d64', borderRadius: '10px', color: 'white', padding: 3, paddingLeft: 10, paddingRight: 10,
                                          marginLeft: '15px', fontSize: '15px', whiteSpace:'nowrap'}}> Backend <b>V2</b></label>
                                      </div>
                                  )}
                                </>
                              )
                            }} >
                              <Outlet />
                            </ThemedLayoutV2>
                          </Authenticated>
                        }
                    >
                      <Route
                          index
                          element={<NavigateToResource resource="customers" />}
                      />
                      <Route path="/measuringdevices">
                        <Route index element={<MeasuringDeviceList />} />
                        <Route path="create" element={<MeasuringDeviceCreate />} />
                        <Route path="edit/:id" element={<MeasuringDeviceEdit />} />
                        <Route path="show/:id" element={<BlogPostShow />} />
                      </Route>
                      <Route path="/devices">
                        <Route index element={<DeviceList />} />
                        <Route path="create" element={<DeviceCreate />} />
                        <Route path="edit/:id" element={<DeviceCreate />} />
                        <Route path="show/:id" element={<DeviceShow />} />
                      </Route>
                      <Route path="/device-categories">
                        <Route index element={<DeviceCategoryList />} />
                        <Route path="create" element={<DeviceCategoryCreate />} />
                        <Route path="edit/:id" element={<DeviceCategoryEdit />} />
                        <Route path="show/:id" element={<DeviceCategoryShow />} />
                      </Route>
                      <Route path="/customers">
                        <Route index element={<CustomerList />} />
                        <Route path="create" element={<CustomerCreate />} />
                        <Route path="edit/:id" element={<CustomerEdit />} />
                        <Route path="show/:id" element={<CustomerShow />} />
                      </Route>
                      <Route path="/inspections">
                        <Route index element={<InspectionsList />} />
                        <Route path="create" element={<MuiInferencer />} />
                        <Route path="edit/:id" element={<InspectionsCreateEdit />} />
                        <Route path="show/:id" element={<InspectionShow />} />
                      </Route>
                      <Route path="/notes">
                        <Route index element={<NotesList />} />
                        <Route path="create" element={<NoteCreateEdit />} />
                        <Route path="edit/:id" element={<NoteCreateEdit />} />
                        <Route path="show/:id" element={<NoteShow />} />
                      </Route>
                      <Route path="/badges">
                        <Route index element={<BadgesList />} />
                        <Route path="create" element={<BadgeSave />} />
                        <Route path="edit/:id" element={<BadgeSave />} />
                        <Route path="show/:id" element={<BadgeShow />} />
                      </Route>
                      <Route path="/admins">
                        <Route index element={<AdminsList />} />
                        <Route path="create" element={<AdminCreateEdit />} />
                        <Route path="edit/:id" element={<AdminCreateEdit />} />
                        <Route path="show/:id" element={<AdminsShow />} />
                      </Route>
                      <Route path="/employees">
                        <Route index element={<EmployeeList />} />
                        <Route path="create" element={<EmployeeCreateEdit />} />
                        <Route path="edit/:id" element={<EmployeeCreateEdit />} />
                        <Route path="show/:id" element={<EmployeeShow />} />
                      </Route>
                    </Route>
                    <Route
                        element={
                          <Authenticated key={'auth-nav'} fallback={<Outlet />}>
                            <NavigateToResource />
                          </Authenticated>
                        }
                    >
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/forgot-password" element={<ForgotPassword />} />
                    </Route>
                    <Route
                        element={
                          <Authenticated key={'auth'}>
                            <ThemedLayoutV2 Header={Header}>
                              <Outlet />
                            </ThemedLayoutV2>
                          </Authenticated>
                        }
                    >
                      <Route path="*" element={<ErrorComponent />} />
                    </Route>
                  </Routes>
                  <RefineKbar />
                  <UnsavedChangesNotifier />
                </Refine>
              </RefineSnackbarProvider>
            {/*</ColorModeContextProvider>*/}
          </RefineKbarProvider>
        </BrowserRouter>
      </ThemeProvider>

  );
}

export default App;