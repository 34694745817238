import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    InputAdornment,
    TextField
} from "@mui/material";
import * as EmailValidator from 'email-validator';
import {useApiUrl, useCustom} from "@refinedev/core";
import {FaSearch} from "react-icons/fa";
import {Mail} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

interface TextInput {
    type: 'mail'
    title: string,
    inspectionId: number|null
    onClose: () => void
}
export const SendErrorReportDialog : React.FC<TextInput> = ({onClose, type, title, inspectionId}) => {

    const [email, setEmail] = useState('')
    const apiUrl = useApiUrl();
    const [sendRequest, setSendRequest] = useState(false);
    const [emailError, setEmailError] = useState<string|null>(null);

    const {data, isLoading, isFetching, isRefetching, error, isPaused, fetchStatus, status , remove, refetch   } = useCustom({
        url: apiUrl + `/inspections/${inspectionId}/report`,
        method: 'post',
        config: {
            payload: {
                recipient: email,
                inspectionId: inspectionId
            },
        },
        queryOptions: {
            enabled: sendRequest && !!inspectionId ,
            retry: false,
            keepPreviousData: false,
            cacheTime: 0

        }
    });

    useEffect(() => {
        if(status == 'error' && error.response && error.response.data) {
            setEmailError(null);
            setSendRequest(false)
            const violations = error.response.data.violations;
            violations.find((violation : any) => {
                if(violation.fieldName === 'recipient' ) {
                    setEmailError("Die Eingabe " + violation.message)
                }
            })
        }
    }, [error, data]);

    useEffect(() => {
        if(!inspectionId) {
            setEmail('');
            setEmailError(null);
            setSendRequest(false)
            remove();
        }
    }, [inspectionId]);

    return (
        <Dialog open={!!inspectionId}  fullWidth
                maxWidth="sm">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {status != 'success' && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <p>Bitte geben Sie die E-Mail-Adresse für den Empfänger der <b>vorläufigen Mängelliste</b> ein. </p>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField  autoComplete={'off'} error={!!emailError} fullWidth variant={"outlined"}  InputProps={{
                                startAdornment: (<InputAdornment position={"start"}><Mail color={emailError ? "error" : "primary"  } /></InputAdornment>)
                            }} placeholder={'E-Mail-Adresse'} value={email} onChange={event => {
                                setEmail(event.target.value);
                                setEmailError(null)
                            }} helperText={emailError} onKeyPress={(ev) => {
                                // console.log(`Pressed keyCode ${ev.key}`);
                                if (ev.key === 'Enter') {
                                    setSendRequest(true);
                                    ev.preventDefault();
                                }
                            }}></TextField>
                        </Grid>
                    </Grid>
                )}
                {status == 'success' && (
                    <Alert severity={"success"} style={{textAlign: 'center'}}>Vorläufiger Fehlerreport wurde erfolgreich an die E-Mail-Adresse <b>{email}</b> geschickt!</Alert>

                )}

            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
                {status != 'success' && (
                    <>
                        <Button onClick={() => {
                            onClose();
                        }}>
                            Abbrechen
                        </Button>
                        <LoadingButton loading={(isLoading || isFetching || isRefetching) && !isPaused && fetchStatus != 'idle' } disabled={email.length === 0} variant={"contained"} onClick={() => {
                            setSendRequest(true);
                        }}>
                            Fehlerprotokoll senden
                        </LoadingButton>
                    </>
                )}
                {status == 'success' && (
                    <>
                        <Button variant={"contained"} onClick={() => {
                            onClose();
                        }} fullWidth={true}>Schließen</Button>
                    </>
                )}

            </DialogActions>
        </Dialog>
    )
}