import {IResourceComponentsProps, useShow, useTranslate} from "@refinedev/core";
import {IBadge, IDevice, IMeasuringDeviceType} from "../../../rest-data-provider/bfaTypes";
import {Show, TagField} from "@refinedev/mui";
import {Chip, Grid, Stack, TextField, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import React from "react";

export const BadgeShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow<IBadge>();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={2}>
                <TextField className={'unimportant'} disabled={true} variant={"outlined"} label={translate('badges.fields.id')} defaultValue={" "} value={record?.id} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={true} label={translate('badges.fields.year')} defaultValue={" "} value={record?.year} />
                    </Grid>
                    <Grid item xs={12} style={{display: 'flex', flexDirection:'column', placeContent: 'center', alignContent:'center'}}>
                        <Typography variant="body1" fontWeight="bold">
                            {translate("badges.fields.image")}
                        </Typography>
                        <img
                            style={{ objectFit: 'contain', width: "100%", height: 150 }}
                            src={record?.image}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField fullWidth className={'unimportant'} disabled={true} label={translate('badges.fields.createdAt')} defaultValue={" "} value={record?.createdAt ? new Date(record!.createdAt).toLocaleString() : '-'} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth className={'unimportant'} disabled={true} label={translate('badges.fields.updatedAt')} defaultValue={" "} value={record?.updatedAt ? new Date(record!.updatedAt).toLocaleString() : '-'} />
                    </Grid>
                </Grid>
            </Stack>
        </Show>
    );
};
