import {IResourceComponentsProps, useShow, useTranslate} from "@refinedev/core";
import {Show} from "@refinedev/mui";
import {Stack, TextField, Typography} from "@mui/material";
import {IDeviceCategory, IMeasuringDeviceType} from "../../../rest-data-provider/bfaTypes";
import "../../../utils/BfADisabledInputs.css";

export const DeviceCategoryShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow<IDeviceCategory>();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            {!isLoading && (
                <Stack gap={2}>
                    <TextField InputLabelProps={{ shrink: true }} disabled={true} variant={"outlined"}  label={translate('measuringdevices.fields.id')} value={record?.id} />
                    <TextField  InputLabelProps={{ shrink: true }} disabled={true} label={translate('measuringdevices.fields.name')}  value={record?.name} />
                    <TextField  InputLabelProps={{ shrink: true }} className={'unimportant'} disabled={true} label={translate('measuringdevices.fields.createdAt')} value={record?.createdAt ? new Date(record!.createdAt).toLocaleString() : '-'} />
                    <TextField  InputLabelProps={{ shrink: true }} className={'unimportant'} disabled={true} label={translate('measuringdevices.fields.updatedAt')} value={record?.updatedAt ? new Date(record!.updatedAt).toLocaleString() : '-'} />
                </Stack>
            )}

        </Show>
    );
};
