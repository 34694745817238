import {CrudFilters, HttpError, OpenNotificationParams, useInvalidate} from "@refinedev/core";
import {UseFormSetError} from "react-hook-form/dist/types/form";
import {axiosInstance} from "../rest-data-provider/utils";
import {IInspection} from "../rest-data-provider/bfaTypes";
import {saveAs} from "file-saver";

export class BfARefineUtils {
    static generateSaveSuccesNotification(ressourceName: string) : OpenNotificationParams {
        return {
            message: `${ressourceName ? ressourceName : 'Daten'} erfolgreich gespeichert`,
            type: 'success'
        }
    }

    static generateDeleteSuccesNotification(ressourceName: string) : OpenNotificationParams {
        return {
            message: `${ressourceName ? ressourceName : 'Daten'} erfolgreich gelöscht`,
            type: 'success'
        }
    }

    static generateDeleteErrorNotification(ressourceName: string) : OpenNotificationParams {
        return {
            message: `Fehler beim Löschen (${ressourceName})`,
            type: "error",
        };
    }

    static base64toBlob (b64Data: string, contentType = "", sliceSize = 512): Blob {
        const byteCharacters = atob(b64Data);
        // console.log(byteCharacters)
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    static generateSaveErrorNotification(ressourceName: string) : OpenNotificationParams {
        return {
            message: `Fehler beim Speichern (${ressourceName})`,
            type: "error",
        };
    }
    static dataGridSearch(data: string) {
        const filters: CrudFilters = [];
        filters.push(
            {
                field: "q",
                operator: "eq",
                value: data,
            },
        );
        return filters;
    }

    static downloadReport(inspection: IInspection, onFinish: () => void) {
        axiosInstance.get(import.meta.env.VITE_API_BASE_URL + `/inspections/${inspection.id}/report`).then(value1 => {
            const blob = BfARefineUtils.base64toBlob(value1.data.reportBase64)
            const filename = value1.data.filename
            // saveAs(blob, this.generateFileName(inspection))
            saveAs(blob, filename)
            onFinish();
        }).catch(reason => {
            alert('fehler');
        });
    }

    static generateFileName(inspection: IInspection, errorReportOnly = false) {
        let customer = inspection.customer.name;
        if(inspection.facility.name && inspection.facility.name.length > 0) {
            customer =  customer + ' - ' + inspection.facility.name;
        }

        let appendix = 'Protokoll';
        if(errorReportOnly) {
            appendix = 'Vorab-Fehler-Protokoll'
        }

        let facilityTypeAppendix = '';
        if(inspection.facility.facilityTypes.length > 0) {
            facilityTypeAppendix = ` - ${inspection.facility.facilityTypes[0].name}`
        }

        return `${this.printDateFilename(new Date(inspection.begin))} ${customer} - E-Geräte-${appendix}${facilityTypeAppendix}.xlsx`;
    }

    static printDateFilename(date:Date) {
        const year:any = date.getFullYear();
        let month:any =  date.getMonth() + 1;
        if(month < 10) {
            month = "0".concat(month.toString());
        }
        let day:any = date.getDate();
        if (day < 10) {
            day = "0".concat(day.toString());
        }
        return `${year}.${month}.${day}`;

    }

    static extractServerSideErrors(error: HttpError, setError: UseFormSetError<any>) {
        if(error) {
            console.log(error.response.data.violations)
            for (const violation of error.response.data.violations) {
                console.log(violation)
                setError(violation.fieldName,{message: violation.message})
            }
        }
    }
}