import React, {useState} from "react";
import {FormAction, HttpError, IResourceComponentsProps, useInvalidate, useShow, useTranslate} from "@refinedev/core";
import {NumberField, RefreshButton, Show, TextFieldComponent} from "@refinedev/mui";
import {Button, Stack, Typography} from "@mui/material";
import {DateField} from "../../../components/fields/date";
import {useParams} from "react-router-dom";
import {FacilitySaveDrawer} from "../facilities/editDrawer";
import {useModalForm, UseModalFormProps, UseModalFormReturnType} from "@refinedev/react-hook-form";
import {ICustomerType, IFacility} from "../../../rest-data-provider/bfaTypes";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {FacilityListV3} from "../facilities/listV3";


export const CustomerShow : React.FC<IResourceComponentsProps<ICustomerType>> = () => {

    const translate = useTranslate();

    const { queryResult } = useShow<ICustomerType>();
    const { data, isLoading } = queryResult;
    const { id} = useParams();

    const [editDrawerVisible, setEditDrawerVisible] = useState(false);
    const [createDrawerVisible, setCreateDrawerVisible] = useState(false);

    const createDrawerProps = (action: FormAction) : UseModalFormProps<IFacility, HttpError, IFacility> => {
        return {
            refineCoreProps: { action: action, resource:`customers/${id}/facilities`,
                onMutationError: error => {},
                onMutationSuccess: (data1, variables, context) => {
                    if(action === "create") {
                        setCreateDrawerVisible(false)
                    } else if (action === "edit") {
                        setEditDrawerVisible(false);
                    }
                },
                errorNotification: (data, values, resource) => {
                    return BfARefineUtils.generateSaveErrorNotification('Einrichtung');
                },
                successNotification : (data1, values, resource) => {
                    return BfARefineUtils.generateSaveSuccesNotification('Einrichtung')
                },
            },
            syncWithLocation: true,
            defaultValues:{
                facilityTypes: []
            }
        }
    }
    const createDrawerFormProps = useModalForm<IFacility, HttpError, IFacility>(createDrawerProps('create'));
    const editDrawerFormProps = useModalForm<IFacility, HttpError, IFacility>(createDrawerProps('edit'));
    const {
        modal: { show: showEditDrawer },
    } = editDrawerFormProps;
    const {
        modal: { show: showCreateDrawer },
    } = createDrawerFormProps;

    const record = data?.data;

    return (
        <>
            <Show isLoading={isLoading} >
                <Stack gap={1}>
                    <Stack gap={3} direction={'row'}>
                        <Stack gap={1}>
                            <Typography variant="body1" fontWeight="bold">
                                {translate("customers.fields.id")}
                            </Typography>
                            <NumberField value={record?.id ?? ""} />
                        </Stack>
                        <Stack gap={1}>
                            <Typography variant="body1" fontWeight="bold">
                                {translate("customers.fields.name")}
                            </Typography>
                            <TextFieldComponent value={record?.name} />
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} gap={3}>
                        <Stack gap={1}>
                            <Typography variant="body1" fontWeight="bold">
                                {translate("customers.fields.createdAt")}
                            </Typography>
                            <DateField value={record?.createdAt} />
                        </Stack>
                        <Stack gap={1}>
                            <Typography variant="body1" fontWeight="bold">
                                {translate("customers.fields.updatedAt")}
                            </Typography>
                            <DateField value={record?.updatedAt} />
                        </Stack>
                        <Stack gap={1}>
                            <Typography variant="body1" fontWeight="bold">
                                {translate("customers.fields.updatedBy")}
                            </Typography>
                            <TextFieldComponent value={record?.updatedBy ? `${record.updatedBy.firstName} ${record.updatedBy.lastName}`  : '-'} />
                        </Stack>
                    </Stack>
                </Stack>
            </Show>
            <FacilityListV3 customer={record} editFacility={(facility) => {
                setEditDrawerVisible(true);
                showEditDrawer(facility.id);
            }} createFacility={() => {
                setCreateDrawerVisible(true)
                showCreateDrawer();
            }} customerId={id}></FacilityListV3>
            {/*<FacilityListV3 editFacility={facility => {*/}
            {/*    showEditDrawer(facility.id);*/}
            {/*}} createFacility={() => {*/}
            {/*    showCreateDrawer();*/}
            {/*}} customer={record} customerId={id}></FacilityListV3>*/}
            {editDrawerVisible && (
                <FacilitySaveDrawer customer={record} {...editDrawerFormProps}></FacilitySaveDrawer>
            )}
            {createDrawerVisible && (
                <FacilitySaveDrawer customer={record} {...createDrawerFormProps}></FacilitySaveDrawer>
            )}

        </>
    );
}