
import {FormAction, HttpError, IResourceComponentsProps, useInvalidate, useShow, useTranslate} from "@refinedev/core";
import {
    IDevice,
    IFacility,
    IInspection,
    IInspectionItem,
    IMeasuringDeviceType
} from "../../../rest-data-provider/bfaTypes";
import {Show, TagField} from "@refinedev/mui";
import {Box, Button, Chip, Grid, Stack, TextField, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import React, {useState} from "react";
import {SiMicrosoftexcel} from "react-icons/si";
import {InspectionItemList} from "../inspectionitems/list";
import {useParams} from "react-router-dom";
import {useModalForm, UseModalFormProps} from "@refinedev/react-hook-form";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {FacilitySaveDrawer} from "../facilities/editDrawer";
import {InspectionsCreateEdit} from "./createEdit";
import {InspectionSaveDrawer} from "../inspectionitems/editCreateDrawer";

export const InspectionShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow<IInspection>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const { id} = useParams();

    const [editDrawerShown, setEditDrawerShown] = useState(false);
    const [createDrawerShown, setCreateDrawerShown] = useState(false);

    const createDrawerProps = (action: FormAction) : UseModalFormProps<IInspectionItem, HttpError, IInspectionItem> => {
        return {
            refineCoreProps: { action: action, resource:`inspections/${id}/items`,
                onMutationError: error => {},
                onMutationSuccess: (data1, variables, context) => {
                    setEditDrawerShown(false);
                },
                errorNotification: (data, values, resource) => {
                    return BfARefineUtils.generateSaveErrorNotification(translate('inspectionItems.inspectionItems'));
                },
                successNotification : (data1, values, resource) => {
                    return BfARefineUtils.generateSaveSuccesNotification(translate('inspectionItems.inspectionItems'))
                },

            },
            syncWithLocation: true,
            modalProps: {
                autoSubmitClose: true
            }
        }
    }
    // const createDrawerFormProps = useModalForm<IInspectionItem, HttpError, IInspectionItem>(createDrawerProps('create'));
    const editDrawerFormProps = useModalForm<IInspectionItem, HttpError, IInspectionItem>(createDrawerProps('edit'));
    const {
        modal: { show: showEditDrawer, visible: editDrawerVisible },

    } = editDrawerFormProps;

    const createDrawerFormProps = useModalForm<IInspectionItem, HttpError, IInspectionItem>(createDrawerProps('create'));
    const {
        modal: { show: showCreateDrawer, visible: createDrawerVisible },

    } = createDrawerFormProps;

    const invalidate = useInvalidate();



    return (
        <>
            <Show headerButtons={({ defaultButtons }) => (
                <>
                    <Button variant={"contained"} color={'success'} startIcon={<SiMicrosoftexcel />} onClick={(evt) => {
                        evt.stopPropagation();
                        if(id && id.length > 0 && record) {
                            BfARefineUtils.downloadReport(record, () => {
                                invalidate({
                                    resource: 'inspections',
                                    invalidates: ['resourceAll']
                                })
                            });
                        }
                    }}>Prüfbericht</Button>
                    {defaultButtons}
                </>)} isLoading={isLoading}>
                <Stack gap={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <TextField fullWidth disabled={true} className={'unimportant'} variant={"outlined"} label={translate('inspections.fields.id')}
                                       defaultValue={" "} value={record?.id} />
                        </Grid>
                        <Grid item xs={record?.id ? 3 : 4}>
                            <TextField className={"unimportant"} fullWidth disabled={true} label={translate('inspections.fields.customer')}
                                       defaultValue={" "} value={record?.customer?.name} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField className={"unimportant"} fullWidth disabled={true} label={translate('inspections.fields.facility')}
                                       defaultValue={" "} value={ `${record?.facility?.name} - ${record?.facility.street} ${record?.facility.houseNumber}, ${record?.facility.zipCode} ${record?.facility.city}`} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField className={"unimportant"} fullWidth disabled={true} label={translate('inspections.fields.facilityType')}
                                       defaultValue={" "} value={ (!!record && !!record.facility && !!record.facility.facilityTypes && record.facility.facilityTypes.length) > 0 ? record?.facility.facilityTypes[0].name : '-'} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth disabled={true} label={translate('inspections.fields.begin')} defaultValue={" "}
                                       value={record?.begin ? new Date(record!.begin).toLocaleDateString() : '-'} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth disabled={true} label={translate('inspections.fields.end')} defaultValue={" "}
                                       value={record?.end ? new Date(record!.end).toLocaleDateString() : '-'} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth disabled={true} label={translate('inspections.fields.leader')} defaultValue={" "}
                                       value={record?.leader ? `${record.leader.firstName} ${record.leader.lastName}` : '-'} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth disabled={true} label={translate('inspections.fields.deadline')} defaultValue={" "}
                                       value={record?.deadline ? `${record.deadline} Monate` : '-'} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth disabled={true} label={translate('inspections.fields.didCreateReport')} defaultValue={" "}
                                       value={record?.didCreateReport ? 'Ja' : 'Nein'} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth className={'unimportant'} disabled={true} label={translate('devices.fields.createdAt')} defaultValue={" "}
                                       value={record?.createdAt ? new Date(record!.createdAt).toLocaleString() : '-'} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth className={'unimportant'} disabled={true} label={translate('devices.fields.updatedAt')} defaultValue={" "}
                                       value={record?.updatedAt ? new Date(record!.updatedAt).toLocaleString() : '-'} />
                        </Grid>
                    </Grid>
                </Stack>
            </Show>
            <InspectionItemList editInspectionItem={inspectionItem => {
                setEditDrawerShown(true)
                showEditDrawer(inspectionItem.id)
            }} createInspectionItem={() => {
                setCreateDrawerShown(true)
                showCreateDrawer();
            }} inspectionId={id} inspection={record} />

            {editDrawerShown && editDrawerVisible && (
                <InspectionSaveDrawer inspection={record} {...editDrawerFormProps}  ></InspectionSaveDrawer>
            )}
            {createDrawerShown && createDrawerVisible && (
                <InspectionSaveDrawer inspection={record} {...createDrawerFormProps}  ></InspectionSaveDrawer>
            )}

        </>
    );
};
