import {SearchField} from "./SearchField";
import {DataGrid, deDE, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {Breadcrumb, CreateButton, List} from "@refinedev/mui";
import React, {ReactNode} from "react";
import "./BfADataGrid.css"
import {ToggleButton} from "@mui/lab";
import {Grid, ToggleButtonGroup} from "@mui/material";


interface BfADataGridProps {
    onSearchTermChanged?: (searchTerm: string) => void
    dataGridProps: any
    onRowClick: (params: GridRowParams) => void
    onCreate?: () => void
    columns: GridColDef[]
    title?: string|undefined|null
    showSearch?: boolean,
    additionalHeaderComponents?: ReactNode,
    showBreadCrumb?: boolean
}


export const BfADataGrid:React.FC<BfADataGridProps> = ({dataGridProps, onSearchTermChanged, columns,
                                                           onRowClick, title, onCreate,
                                                           showSearch = true, additionalHeaderComponents, showBreadCrumb = true}) => {
    let createButtonProps:any = {}
    if(onCreate) {
        createButtonProps = {createButtonProps: {
            onClick: (event:any) => {
                onCreate();
            }}
        }
    }

    return (
        <List breadcrumb={showBreadCrumb ? <Breadcrumb /> : false} {...createButtonProps} headerButtons={({ defaultButtons }) => (
            <>
                {/*{additionalHeaderComponents && (*/}
                {/*    additionalHeaderComponents*/}
                {/*)}*/}
                {defaultButtons}
            </>
        )}  title={title ? title : dataGridProps.title}>
            <Grid container gap={2}>
                {additionalHeaderComponents && (
                    <Grid item>
                        {additionalHeaderComponents}
                    </Grid>
                )}

                <Grid xs={true}>
                    {showSearch && (
                        <SearchField searchTermChanged={searchString => {
                            // setSearchString(searchString)
                            if(onSearchTermChanged) {
                                onSearchTermChanged(searchString);
                            }

                        }}></SearchField>
                    )}
                </Grid>
            </Grid>


            <DataGrid localeText={deDE.components.MuiDataGrid.defaultProps.localeText} showCellRightBorder={true}
                      getRowHeight={() => 'auto'}  {...dataGridProps}
                      sx={{
                          "& .MuiDataGrid-root": {
                              borderRadius: "50px",
                          },
                          "& .MuiDataGrid-cell": {
                              borderRight: '1px solid lightgrey',
                          },
                          "& .MuiDataGrid-cell:last-child": {
                              borderRight: 0
                          },
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiDataGrid-row:hover': {
                              cursor: 'pointer'
                          },
                          "& .MuiDataGrid-columnHeaders": {
                              backgroundColor: "#fff",
                              color: "#000",

                              fontSize: 14,
                              fontWeight: '500 !!!important',
                              '--unstable_DataGrid-headWeight': 600
                          },
                          "& .MuiDataGrid-columnHeader": {
                              borderRight: '1px solid lightgrey',
                              borderBottom: '1px solid lightgrey',
                          },
                          "& .MuiDataGrid-columnHeader:last-child": {
                              borderRight: '0px solid lightgrey',
                          },
                          "& .MuiDataGrid-columnHeader:focus": {
                              outline: 'none'
                          }
                      }}
                      getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                      }
                      disableColumnFilter={true}
                      filterModel={undefined}
                      columns={columns}
                      autoHeight
                      // onRowClick={params => {
                      //     show('customers', params.id)
                      // }}
                      onRowClick={onRowClick}
            />
        </List>
    )
}