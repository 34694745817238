import {IResourceComponentsProps, useTranslate} from "@refinedev/core";
import {useDataGrid} from "@refinedev/mui";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import React from "react";
import {GridColDef} from "@mui/x-data-grid";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";
import {Chip, Stack, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import {ICustomerType, IFacility, IInspection, IInspectionItem} from "../../../rest-data-provider/bfaTypes";
import {DateField} from "../../../components/fields/date";

interface InspectionItemsListProps {
    editInspectionItem: (inspectionItem : IInspectionItem) => void,
    createInspectionItem: () => void,
    inspection?: IInspection
    inspectionId?: string
}

type CombinedProps = IResourceComponentsProps<IInspectionItem> & InspectionItemsListProps;

export const InspectionItemList : React.FC<CombinedProps> = ({editInspectionItem, createInspectionItem, inspection, inspectionId}) => {
    const translate = useTranslate();
    const { dataGridProps, search } = useDataGrid(
        {
            onSearch: BfARefineUtils.dataGridSearch,
            resource:`inspections/${inspectionId}/items`,
            pagination: {pageSize: 100}
        });

    const columns = React.useMemo<GridColDef<IInspectionItem>[]>(
        () => [
            // {
            //     field: "id",
            //     headerName: translate("customers.fields.id"),
            //     type: "number",
            //     minWidth: 50,
            // },
            {
                field: "storey",
                headerName: translate("inspectionItems.fields.storey"),
                minWidth: 100,
                maxWidth: 150,
                flex: 1
            },
            {
                field: "room",
                headerName: translate("inspectionItems.fields.room"),
                minWidth: 100,
                maxWidth: 150,
                flex: 1
            },
            {
                field: "deviceNumber",
                headerAlign: 'center',
                align:'center',
                headerName: translate("inspectionItems.fields.deviceNumber"),
                minWidth: 100,
            },
            {
                field: "device",
                headerName: translate("inspectionItems.fields.device"),
                minWidth: 100,
                flex:1
            },
            {
                field: "protectionClass",
                align:'center',
                headerAlign: 'center',
                headerName: translate("inspectionItems.fields.protectionClassShort"),
                minWidth: 100
            },
            {
                field: "measuringDevice",
                headerName: translate("inspectionItems.fields.measuringDevice"),
                minWidth: 100,
                maxWidth: 200,
                flex: 1,
            },
            {
                field: "inspector",
                headerName: translate("inspectionItems.fields.inspector"),
                minWidth: 100,
                maxWidth: 200,
                flex: 1,
                valueGetter: params => {
                    return `${params.row.inspector.firstName} ${params.row.inspector.lastName}`
                }
            },
            {
                field: "result",
                headerName: translate("inspectionItems.fields.result"),
                minWidth: 100,
                align: 'center',
                alignHeader: 'center',
                renderCell: params => {
                    if(params.row.result) {
                        return (
                            <Typography variant={'body2'} style={{backgroundColor: 'darkgreen', paddingLeft: 10, paddingRight: 10, borderRadius: 5}} color={'white'}>i.O.</Typography>
                        )
                    }
                    return (
                        <Typography variant={'body2'} style={{backgroundColor: 'darkred', paddingLeft: 10, paddingRight: 10, borderRadius: 5}} color={'white'}>n.i.O.</Typography>
                    )

                }
            },
            {
                field: "action",
                headerName: translate("inspectionItems.fields.defectInfo"),
                minWidth: 200,
                maxWidth: 300,
                flex: 1,
                renderCell: (params) => {
                    if(params.row.result) {
                        return <Typography variant={'body2'} fontStyle={"italic"}>{params.row.comment}</Typography>
                    } else {
                        return <Typography variant={'body2'} fontWeight={"bold"} color={'red'}>{params.row.comment}</Typography>
                    }
                },
            },
            {
                field: "recommendation",
                headerName: translate("inspectionItems.fields.status"),
                minWidth: 200,
                maxWidth: 300,
                flex: 1,
                renderCell: (params) => {
                    if(params.row.action) {
                        return <Typography variant={'body2'} color={'#58AF6A'} fontWeight={"bold"}>{params.row.action}</Typography>
                    }
                    if(params.row.recommendation && params.row.recommendation.length > 0) {
                        if(params.row.result) {
                            return <Typography variant={'body2'} fontStyle={"italic"}>{params.row.recommendation}</Typography>
                        } else {
                            return <Typography variant={'body2'} fontWeight={"bold"} color={'red'}>{params.row.recommendation}</Typography>
                        }
                    }
                }
            },
            {
                field: "createdAt",
                headerName: translate("inspectionItems.fields.createdAt"),
                minWidth: 200,
                maxWidth: 300,
                flex: 1,
                renderCell: function render({ value }) {
                    return <DateField format={'L LT'} value={value} />;
                },
            }
        ],
        [translate, inspection],
    );
    return (
        <BfADataGrid showBreadCrumb={false} title={translate('inspectionItems.inspectionItems')} onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
            editInspectionItem(params.row);
        }} onCreate={() => {
            createInspectionItem();
        }} columns={columns} />
    );
}