import { Create, Edit } from "@refinedev/mui";
import {Box, Grid, TextField} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import React, {useState} from "react";
import {Controller, FieldValues} from "react-hook-form";
import {MuiFileInput} from "mui-file-input";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {HttpError, useList, useTranslate} from "@refinedev/core";
import {IDevice, IDeviceCategory} from "../../../rest-data-provider/bfaTypes";
import {CheckBoxGroup} from "../../../components/custom/CheckBoxGroup";
import {IFile, ImageUploadComponent} from "../../../components/custom/ImageUploadComponent";
import {gridFilteredSortedRowEntriesSelector} from "@mui/x-data-grid";

export const DeviceCreate = () => {
    const {
        saveButtonProps,
        refineCore: { formLoading, onFinish, queryResult },
        register,
        control,
        formState: { errors },
        handleSubmit,
        setError

    } = useForm<IDevice>(
        {
            refineCoreProps: {
                onMutationError: (error, variables, context) => {
                    BfARefineUtils.extractServerSideErrors(error, setError);
                },
            },
        });
    const translate = useTranslate();

    const onFinishHandler = (data: FieldValues) => {
        var fileData = {};
        if(file && file.fileBase64 && file.filename) {
            fileData = {
                upload: {
                    fileName: file.filename,
                    fileBase64: file.fileBase64
                }
            }
        }
        onFinish({
            ...data,
            ...fileData
        });
    };

    const [file,setFile] = useState<IFile|null>(null);
    let queryResultDeviceCategories = useList<IDeviceCategory, HttpError, IDeviceCategory>({resource: 'device-categories'});
    let allCategories = queryResultDeviceCategories.data?.data;

    const formElements = (
        <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            autoComplete="off"
        >
            {queryResult?.data?.data.id && (
                <TextField
                    {...register("id", {})}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate('devices.fields.id')}
                    name="id"
                    disabled
                />
            )}
            <TextField
                {...register("name", {})}
                error={!!(errors as any)?.name}
                helperText={(errors as any)?.name?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('devices.fields.name')}
                name="name"
            />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        {...register("sorting", {
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.sorting}
                        helperText={(errors as any)?.sorting?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        label={translate('devices.fields.sorting')}
                        name="sorting"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...register("defaultProtectionClass", {
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.defaultProtectionClass}
                        helperText={
                            (errors as any)?.defaultProtectionClass?.message
                        }
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        label={translate('devices.fields.defaultProtectionClass')}
                        name="defaultProtectionClass"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller name={"uploadFile"} control={control} render={({field, formState, fieldState}) =>
                        (
                            <ImageUploadComponent
                                didChangeImage={file1 => {
                                    setFile(file1);
                                }}
                                label={translate('devices.fields.image')} hasError={!!(formState.errors as any)?.upload} initialImageUrl={queryResult?.data?.data.image} errorText={(formState.errors as any)?.uploadFile?.message}
                            />
                        )
                    } />

                </Grid>
                <Grid item xs={6}>
                    <Controller name={"categories"}  control={control}
                        render={({
                                     field: { onChange, onBlur, value, name, ref },
                                     fieldState: { invalid, isTouched, isDirty, error },
                                     formState,
                                 }) => {
                            return (
                                <CheckBoxGroup onChange={selectedOptions => {
                                    onChange(selectedOptions);
                                }} title={translate('devices.fields.categories')} options={allCategories}
                                               selectedOptions={value}></CheckBoxGroup>
                            )
                        }} />
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <>
            {queryResult?.data?.data.id && (
                <Edit isLoading={formLoading}  saveButtonProps={{onClick: handleSubmit(onFinishHandler) }}>
                    {formElements}
                </Edit>
            )}
            {!queryResult?.data?.data.id && (
                <Create isLoading={formLoading}  saveButtonProps={{onClick: handleSubmit(onFinishHandler) }}>
                    {formElements}
                </Create>
            )}
        </>
    );
};
