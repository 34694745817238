import {IResourceComponentsProps, useShow, useTranslate} from "@refinedev/core";
import {IAdmin, IDevice, IMeasuringDeviceType, INote} from "../../../rest-data-provider/bfaTypes";
import {Show, TagField} from "@refinedev/mui";
import {Checkbox, Chip, FormControlLabel, FormGroup, Grid, Stack, Switch, TextField, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import React from "react";

export const AdminsShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow<IAdmin>();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={2}>
                <TextField className={'unimportant'}  disabled={true} variant={"outlined"} label={translate('admins.fields.id')} defaultValue={" "} value={record?.id} />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField disabled={true} fullWidth label={translate('admins.fields.firstName')} defaultValue={" "} value={record?.firstName} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField disabled={true} fullWidth label={translate('admins.fields.lastName')} defaultValue={" "} value={record?.lastName} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth disabled={true} label={translate('admins.fields.email')} defaultValue={" "} value={record?.email} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type={'password'} fullWidth disabled={true} label={translate('admins.fields.password')} defaultValue={"xxxxxxx"} value={'xxxxxxxx'} />
                    </Grid>
                    <Grid item xs={12} style={{display: 'flex', flexDirection:'row', placeContent:'center'}}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={!record?.isActive} />} label={translate('admins.fields.isActive')} />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Stack>
        </Show>
    );
};
