import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Controller} from "react-hook-form";
import {TextField} from "@mui/material";
import dayjs from "dayjs";
import Calendar from '@mui/icons-material/Event';

export interface BfADatePickerProps {
    name: string;
    label: string;
    control: any;
    defaultValue?: any;

}

function BfADatePicker({
                            name,
                            label,
                            control,
                            defaultValue,
                        }: BfADatePickerProps) {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue ?? null}
            render={({ field: { onChange, value, ref, name }, fieldState }) => (
                <LocalizationProvider adapterLocale={'de'} dateAdapter={AdapterDayjs}>
                    <DatePicker
                        key={name}
                        sx={{marginTop: '15px'}}
                        onChange={(value1, context) => {
                            setTimeout(() => {
                                onChange(value1, context);
                            }, 300)
                        }}
                        value={dayjs(value)}
                        label={label}
                        ref={ref}

                        slots={{
                            textField: props => (
                                <TextField
                                    key={`text-${name}`}
                                    fullWidth
                                    sx={{ width: '100%' }}
                                    {...props}
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState?.error?.message}
                                />
                            )
                        }}
                    />
                </LocalizationProvider>
            )}
        />
    );
}

export default BfADatePicker;