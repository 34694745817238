import {useNavigation, useTranslate} from "@refinedev/core";

import React from "react";
import {List, useDataGrid,} from "@refinedev/mui";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {DateField} from "../../../components/fields/date";
import {SearchField} from "../../../components/custom/SearchField";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";

export const MeasuringDeviceList = () => {
  const translate = useTranslate();
  const { show } = useNavigation();
  const { dataGridProps, search } = useDataGrid(
      {
        onSearch: BfARefineUtils.dataGridSearch,
        pagination: {pageSize: 100}
      });

  const columns = React.useMemo<GridColDef[]>(
  () => [
    {
      field: "id",
      headerName: "Id",
      type: "number",
      minWidth: 50,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
    },
    {
      field: "serialNumber",
      headerName: "Seriennummer",
      minWidth: 200,
    },
    {
      field: "createdAt",
      // flex: 1,
      headerName: translate("measuringdevices.fields.createdAt"),
      minWidth: 200,
      maxWidth: 300,
      renderCell: function render({ value }) {
        return <DateField value={value} />;
      },
    },
    {
      field: "updatedAt",
      // flex: 1,
      headerName: translate("measuringdevices.fields.updatedAt"),
      minWidth: 200,
      maxWidth: 300,
      renderCell: function render({ value }) {
        return <DateField value={value} />;
      },
    }
  ],
  [translate],
  );

  return (
      <BfADataGrid onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
        show('measuringdevices', params.id)
      }} columns={columns} />
  );
};
