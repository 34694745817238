import {useNavigation, useTranslate} from "@refinedev/core";

import React from "react";
import {useDataGrid,} from "@refinedev/mui";
import {GridColDef} from "@mui/x-data-grid";
import {DateField} from "../../../components/fields/date";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";
import {TbLock, TbLockOpen} from "react-icons/tb";

export const EmployeeList = () => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const { dataGridProps, search } = useDataGrid(
        {
            onSearch: BfARefineUtils.dataGridSearch,
            pagination: {pageSize: 100}
        });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("employees.fields.id"),
                type: "number",
                minWidth: 50,
                align: 'center',
                headerAlign: 'center'
            },
            {
                field: "firstName",
                headerName: translate("employees.fields.firstName"),
                flex: 1,
                minWidth: 200,
            },
            {
                field: "lastName",
                headerName: translate("employees.fields.lastName"),
                flex: 1,
                minWidth: 200,
            },
            {
                field: "username",
                headerName: translate("employees.fields.username"),
                flex: 1,
                minWidth: 200,
            },
            {
                field: "email",
                headerName: translate("employees.fields.email"),
                flex: 1,
                minWidth: 200,
            },
            {
                field: "blocked",
                headerName: translate("employees.fields.blocked"),
                minWidth: 100,
                maxWidth: 100,
                align:'center',
                headerAlign: 'center',
                renderCell: params => {
                    return (
                        <div>
                            {!params.value && (
                                <TbLockOpen size={24} color={'green'}></TbLockOpen>
                            )}
                            {params.value && (
                                <TbLock size={24} color={'red'}></TbLock>
                            )}
                        </div>
                    )
                }
            },
            {
                field: "createdAt",
                // flex: 1,
                headerName: translate("employees.fields.createdAt"),
                minWidth: 150,
                maxWidth: 150,
                align: 'center',
                headerAlign: 'center',
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "updatedAt",
                // flex: 1,
                headerName: translate("employees.fields.updatedAt"),
                minWidth: 150,
                maxWidth: 150,
                align: 'center',
                headerAlign: 'center',
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            }
        ],
        [translate],
    );

    return (
        <BfADataGrid showSearch={false} onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
            show('employees', params.id)
        }} columns={columns} />
    );
};
