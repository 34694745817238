import {IResourceComponentsProps, useShow, useTranslate} from "@refinedev/core";
import {IAdmin, IDevice, IEmployee, IMeasuringDeviceType, INote} from "../../../rest-data-provider/bfaTypes";
import {Show, TagField} from "@refinedev/mui";
import {Checkbox, Chip, FormControlLabel, FormGroup, Grid, Stack, Switch, TextField, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import React from "react";

export const EmployeeShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow<IEmployee>();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={2}>
                <TextField className={'unimportant'}  disabled={true} variant={"outlined"} label={translate('employees.fields.id')} defaultValue={" "} value={record?.id} />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField disabled={true} fullWidth label={translate('employees.fields.firstName')} defaultValue={" "} value={record?.firstName} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField disabled={true} fullWidth label={translate('employees.fields.lastName')} defaultValue={" "} value={record?.lastName} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth disabled={true} label={translate('employees.fields.username')} defaultValue={" "} value={record?.username} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth disabled={true} label={translate('employees.fields.email')} defaultValue={" "} value={record?.email} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth disabled={true} label={translate('employees.fields.email')} defaultValue={" "} value={record?.phoneNumber} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type={'password'} fullWidth disabled={true} label={translate('employees.fields.password')} defaultValue={"xxxxxxx"} value={'xxxxxxxx'} />
                    </Grid>

                    <Grid item xs={6} style={{display: 'flex', flexDirection:'column', placeContent: 'center', alignContent:'center'}}>
                        <Typography variant="body1">
                            {translate("employees.fields.image")}
                        </Typography>
                        <img
                            style={{ objectFit: 'contain', width: "100%", height: 150 }}
                            src={record?.image}
                        />
                    </Grid>
                    <Grid item xs={6} style={{display: 'flex', flexDirection:'row', placeContent:'center', placeItems: 'center'}}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox disabled={true} checked={record?.blocked} />} label={translate('employees.fields.blocked')} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth className={'unimportant'} disabled={true} label={translate('employees.fields.createdAt')} defaultValue={" "} value={record?.createdAt ? new Date(record!.createdAt).toLocaleString() : '-'} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth className={'unimportant'} disabled={true} label={translate('employees.fields.updatedAt')} defaultValue={" "} value={record?.updatedAt ? new Date(record!.updatedAt).toLocaleString() : '-'} />
                    </Grid>
                </Grid>
            </Stack>
        </Show>
    );
};
