import {IResourceComponentsProps, useTranslate} from "@refinedev/core";
import { MuiEditInferencer } from "@refinedev/inferencer/mui";
import {useForm} from "@refinedev/react-hook-form";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {useParams} from "react-router-dom";
import {Edit} from "@refinedev/mui";
import {Box, TextField} from "@mui/material";
import {IMeasuringDeviceType} from "../../../rest-data-provider/bfaTypes";

export const MeasuringDeviceEdit: React.FC = () => {
  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    control,
    formState: { errors },
    setError
  } = useForm<IMeasuringDeviceType>({
    refineCoreProps: {
      onMutationError: error => {
        BfARefineUtils.extractServerSideErrors(error, setError);
      },
      redirect: "show",
    }
  });
  const translate = useTranslate();


  return (
      <>
        <Edit saveButtonProps={saveButtonProps}>
          <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column" }}
              autoComplete="off"
          >
            <TextField
                {...register("id", {
                  required: "This field is required",
                  valueAsNumber: true,
                })}
                error={!!(errors as any)?.id}
                helperText={(errors as any)?.id?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="number"
                label={translate('measuringdevices.fields.id')}
                name="id"
                disabled
            />
            <TextField
                {...register("name")}
                error={!!(errors as any)?.name}
                helperText={(errors as any)?.name?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('measuringdevices.fields.name')}
                name="name"
            />
            <TextField
                {...register("serialNumber")}
                error={!!(errors as any)?.serialNumber}
                helperText={(errors as any)?.serialNumber?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('measuringdevices.fields.serialNumber')}
                name="serialNumber"
            />
          </Box>

        </Edit>
      </>
  )
};
