import {useTranslate} from "@refinedev/core";
import {useForm} from "@refinedev/react-hook-form";
import {IBfABaseType} from "../../../rest-data-provider/bfaTypes";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {Create, Edit} from "@refinedev/mui";
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {Controller} from "react-hook-form";
import React from "react";

export const NoteCreateEdit : React.FC = () => {
    const translate = useTranslate();

    const {
        saveButtonProps,
        refineCore: { queryResult , formLoading},
        register,
        control,
        formState: { errors },
        setError
    } = useForm<IBfABaseType>({
        refineCoreProps: {
            onMutationError: error => {
                BfARefineUtils.extractServerSideErrors(error, setError);
            },
        }
    });


    const generateFormFields = (edit: boolean) =>  {
        return (
            <>
                <Box
                    component="form"
                    sx={{ display: "flex", flexDirection: "column" }}
                    autoComplete="off"
                >
                    {queryResult?.data?.data.id && (
                        <TextField
                            className={'unimportant'}
                            {...register("id", {})}
                            error={!!(errors as any)?.id}
                            helperText={(errors as any)?.id?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate('devices.fields.id')}
                            name="id"
                            disabled
                        />
                    )}
                    <TextField
                        {...register("name")}
                        error={!!(errors as any)?.name}
                        helperText={(errors as any)?.name?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate('measuringdevices.fields.name')}
                        name="name"
                    />

                    <FormControl sx={{ fieldset: { legend: {maxWidth: '100%'}} }} style={{marginTop: '15px'}} fullWidth>
                        <InputLabel error={!!(errors as any)?.category} id="demo-simple-select-label"  shrink={true}>{translate('notes.fields.category')}</InputLabel>
                        <Controller
                            control={control}
                            render={({
                                field,
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => {
                                return (
                                    <>
                                        {(!edit ||( edit && field.value))&& (
                                            <Select
                                                {...field}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label={translate('notes.fields.category')}
                                                error={!!error}
                                            >
                                                <MenuItem value={'Mangel'} >Mangel</MenuItem>
                                                <MenuItem value={'Hinweis'} >Hinweis</MenuItem>
                                                {/*<MenuItem value={'Information'}>Information</MenuItem>*/}
                                            </Select>
                                        )}
                                    </>
                                )}} name={"category"} />
                        <FormHelperText error={!!(errors as any)?.name}>{(errors as any)?.name?.message}</FormHelperText>
                    </FormControl>
                </Box>
            </>
        )
    }

    return (
        <>
            {!queryResult?.data?.data.id && (
                <Create isLoading={formLoading} saveButtonProps={saveButtonProps} >
                    {generateFormFields(false)}
                </Create>
            )}
            {queryResult?.data?.data.id && (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} >
                    {generateFormFields(true)}
                </Edit>
            )}
        </>
    );
}