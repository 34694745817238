import {useNavigation, useTranslate} from "@refinedev/core";

import React from "react";
import {useDataGrid,} from "@refinedev/mui";
import {GridColDef} from "@mui/x-data-grid";
import {DateField} from "../../../components/fields/date";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";
import {TbLock, TbLockOff, TbLockOpen} from "react-icons/tb";

export const AdminsList = () => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const { dataGridProps, search } = useDataGrid(
        {
            onSearch: BfARefineUtils.dataGridSearch,
            pagination: {pageSize: 100}
        });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("admins.fields.id"),
                type: "number",
                minWidth: 50,
                align: 'center',
                headerAlign: 'center'
            },
            {
                field: "firstName",
                headerName: translate("admins.fields.firstName"),
                flex: 1,
                minWidth: 300,
            },
            {
                field: "lastName",
                headerName: translate("admins.fields.lastName"),
                flex: 1,
                minWidth: 300,
            },
            {
                field: "email",
                headerName: translate("admins.fields.email"),
                flex: 1,
                minWidth: 300,
            },
            {
                field: "isActive",
                headerName: translate("admins.fields.isActive"),
                minWidth: 100,
                maxWidth: 100,
                align:'center',
                headerAlign: 'center',
                renderCell: params => {
                    return (
                        <div>
                            {params.value && (
                                <TbLockOpen size={24} color={'green'}></TbLockOpen>
                            )}
                            {!params.value && (
                                <TbLock size={24} color={'red'}></TbLock>
                            )}
                        </div>
                    )
                }
            },

        ],
        [translate],
    );

    return (
        <BfADataGrid showSearch={false} onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
            show('admins', params.id)
        }} columns={columns} />
    );
};
