import { Edit } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import {useParams} from "react-router-dom";
import {ICustomerType} from "../../../rest-data-provider/bfaTypes";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";

export const CustomerEdit = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
        setError
    } = useForm<ICustomerType>({
        refineCoreProps: {
            onMutationError: error => {
                BfARefineUtils.extractServerSideErrors(error, setError);
            },
            redirect: "show",
        }
    });

    const kundenData = queryResult?.data?.data;
    const {id} = useParams();

    return (
        <>
            <Edit saveButtonProps={saveButtonProps}>
                <Box
                    component="form"
                    sx={{ display: "flex", flexDirection: "column" }}
                    autoComplete="off"
                >
                    <TextField
                        {...register("id", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.id}
                        helperText={(errors as any)?.id?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        label="Id"
                        name="id"
                        disabled
                    />
                    <TextField
                        {...register("name")}
                        error={!!(errors as any)?.name}
                        helperText={(errors as any)?.name?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Name"
                        name="name"
                    />
                </Box>

            </Edit>
        </>

    );
};
