import { Create, Edit } from "@refinedev/mui";
import {Box, Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import React, {useState} from "react";
import {Controller, FieldValues} from "react-hook-form";
import {MuiFileInput} from "mui-file-input";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {HttpError, useList, useTranslate} from "@refinedev/core";
import {IAdmin, IDevice, IDeviceCategory} from "../../../rest-data-provider/bfaTypes";
import {CheckBoxGroup} from "../../../components/custom/CheckBoxGroup";
import {IFile, ImageUploadComponent} from "../../../components/custom/ImageUploadComponent";
import {gridFilteredSortedRowEntriesSelector} from "@mui/x-data-grid";

export const AdminCreateEdit = () => {
    const {
        saveButtonProps,
        refineCore: { formLoading, onFinish, queryResult },
        register,
        control,
        formState: { errors },
        handleSubmit,
        setError

    } = useForm<IAdmin>(
        {
            refineCoreProps: {
                onMutationError: (error, variables, context) => {
                    BfARefineUtils.extractServerSideErrors(error, setError);
                },

            },
            defaultValues: {
                isActive: true
            }
        });
    const translate = useTranslate();


    const formElements = (
        <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            autoComplete="off"
        >
            <Grid container spacing={2}>

                {queryResult?.data?.data.id && (
                    <Grid item xs={12}>
                        <TextField
                            {...register("id", {})}
                            error={!!(errors as any)?.id}
                            helperText={(errors as any)?.id?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate('admins.fields.id')}
                            name="id"
                            disabled
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <TextField
                        {...register("firstName", {})}
                        error={!!(errors as any)?.firstName}
                        helperText={(errors as any)?.firstName?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate('admins.fields.firstName')}
                        name="firstName"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...register("lastName")}
                        error={!!(errors as any)?.lastName}
                        helperText={(errors as any)?.lastName?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate('admins.fields.lastName')}
                        name="lastName"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...register("email")}
                        error={!!(errors as any)?.email}
                        helperText={
                            (errors as any)?.email?.message
                        }
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="email"
                        label={translate('admins.fields.email')}
                        name="email"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField {...register("password")}
                        error={!!(errors as any)?.password}
                        helperText={(errors as any)?.password?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="password"
                        label={translate('admins.fields.password')}
                        name="password">
                    </TextField>
                </Grid>


                <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', placeContent: 'center', placeItems: 'center'}}>
                    <Controller
                        control={control}
                        name="isActive"
                        // eslint-disable-next-line
                        defaultValue={null as any}
                        render={({ field }) => (
                            <FormControlLabel
                                label={translate("admins.fields.isActive")}
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={!field.value}
                                        onChange={(event) => {
                                            field.onChange(!event.target.checked);
                                        }}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <>
            {queryResult?.data?.data.id && (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} >
                    {formElements}
                </Edit>
            )}
            {!queryResult?.data?.data.id && (
                <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {formElements}
                </Create>
            )}
        </>
    );
};
