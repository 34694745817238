import { Create, Edit } from "@refinedev/mui";
import {Box, Button, Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import React, {useState} from "react";
import {Controller, FieldValues} from "react-hook-form";
import {MuiFileInput} from "mui-file-input";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {HttpError, useList, useTranslate} from "@refinedev/core";
import {IDevice, IDeviceCategory, IEmployee} from "../../../rest-data-provider/bfaTypes";
import {IFile, ImageUploadComponent} from "../../../components/custom/ImageUploadComponent";

export const EmployeeCreateEdit = () => {
    const {
        saveButtonProps,
        refineCore: { formLoading, onFinish, queryResult },
        register,
        control,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors

    } = useForm<IEmployee>(
        {
            refineCoreProps: {
                onMutationError: (error, variables, context) => {
                    BfARefineUtils.extractServerSideErrors(error, setError);
                },

            },
        });
    const translate = useTranslate();


    const onFinishHandler = (data: FieldValues) => {
        var fileData = {};
        if(file && file.fileBase64 && file.filename) {
            fileData = {
                upload: {
                    fileName: file.filename,
                    fileBase64: file.fileBase64
                }
            }
        }
        onFinish({
            ...data,
            ...fileData
        });
    };

    const [file,setFile] = useState<IFile|null>(null);

    const formElements = (
        <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            autoComplete="off"
        >
            <Grid container spacing={2}>

                {queryResult?.data?.data.id && (
                    <Grid item xs={12}>
                        <TextField
                            {...register("id", {})}
                            error={!!(errors as any)?.id}
                            helperText={(errors as any)?.id?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate('employees.fields.id')}
                            name="id"
                            disabled
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <TextField
                        {...register("firstName", {})}
                        error={!!(errors as any)?.firstName}
                        helperText={(errors as any)?.firstName?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate('employees.fields.firstName')}
                        name="firstName"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...register("lastName")}
                        error={!!(errors as any)?.lastName}
                        helperText={(errors as any)?.lastName?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate('employees.fields.lastName')}
                        name="lastName"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...register("username")}
                        error={!!(errors as any)?.username}
                        helperText={(errors as any)?.username?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate('employees.fields.username')}
                        name="username"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...register("email")}
                        error={!!(errors as any)?.email}
                        helperText={
                            (errors as any)?.email?.message
                        }
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="email"
                        label={translate('employees.fields.email')}
                        name="email"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...register("phoneNumber")}
                        error={!!(errors as any)?.phoneNumber}
                        helperText={
                            (errors as any)?.phoneNumber?.message
                        }
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="string"
                        label={translate('employees.fields.phonenumber')}
                        name="phoneNumber"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField {...register("password")}
                               error={!!(errors as any)?.password}
                               helperText={(errors as any)?.password?.message}
                               margin="normal"
                               fullWidth
                               InputLabelProps={{ shrink: true }}
                               type="password"
                               label={translate('employees.fields.password')}
                               name="password">
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Controller name={"upload"} control={control} render={({field, formState, fieldState}) =>
                         (
                            <ImageUploadComponent
                                didChangeImage={file1 => {
                                setFile(file1);
                            }}
                                    label={translate('employees.fields.image')} hasError={!!(formState.errors as any)?.upload} initialImageUrl={queryResult?.data?.data.image} errorText={(formState.errors as any)?.upload?.message}
                            />
                        )
                    } />

                </Grid>


                <Grid item xs={6} style={{display: 'flex', flexDirection: 'row', placeContent: 'center', placeItems: 'center'}}>
                    <Controller
                        control={control}
                        name="blocked"
                        // eslint-disable-next-line
                        defaultValue={false}
                        render={({ field }) => (
                            <FormControlLabel
                                label={translate("employees.fields.blocked")}
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field?.value}
                                        onChange={(event) => {
                                            field.onChange(event.target.checked);
                                        }}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <>

            {queryResult?.data?.data.id && (
                <Edit isLoading={formLoading} saveButtonProps={{onClick: handleSubmit(onFinishHandler)}} >
                    {formElements}
                </Edit>
            )}
            {!queryResult?.data?.data.id && (
                <Create isLoading={formLoading} saveButtonProps={{onClick: handleSubmit(onFinishHandler)}} >
                    {formElements}
                </Create>

            )}
        </>
    );
};
