import {MuiFileInput} from "mui-file-input";
import React, {useEffect, useState} from "react";
import {Button, IconButton, Typography} from "@mui/material";
import {BiPencil} from "react-icons/bi";
import {motion} from "framer-motion";
import {getMinimalContentHeight} from "@mui/x-data-grid/hooks/features/rows/gridRowsUtils";

export interface ImageUploadComponentProps {
    label: string,
    hasError: boolean,
    errorText?: string,
    initialImageUrl?: string
    didChangeImage: (file: IFile) => void
}

export interface IFile {
    filename?: string|null,
    fileBase64?: string|null
}

export const ImageUploadComponent: React.FC<ImageUploadComponentProps> = ({label,errorText, hasError, initialImageUrl, didChangeImage }) => {

    const [file, setFile] = useState<any|null>(null);
    const [fileName, setFileName] = useState<string|null>(null)
    const [imageBase64, setImageBase64] = useState<string|null>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        let fb64 = imageBase64;
        if(fb64?.startsWith('data')) {
            fb64 = fb64?.split(',')[1];
        }
        didChangeImage({
            filename: fileName,
            fileBase64: fb64
        })
    }, [fileName, imageBase64])

    useEffect(() => {
        if(initialImageUrl) {
            setLoading(true)
            getBase64ImageFromUrl(initialImageUrl).then((value: string) => {
                setImageBase64(value);
                setLoading(false)
            }).catch(reason => {
                setLoading(false)
            })
        }
    }, [initialImageUrl, reload])

    const getBase64ImageFromUrl = async (imageUrl:string): Promise<string> => {
        let res = await fetch(imageUrl);
        let blob = await res.blob();

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.addEventListener("load", function () {
                resolve(reader.result as string);
            }, false);
            reader.onerror = () => {
                return reject(this);
            };
            reader.readAsDataURL(blob);
        });
    }

    const convertToBase64 = (file:any)=> {
        if(file) {
            const reader = new FileReader()
            console.log(file);
            reader.readAsDataURL(file)
            setFileName(file.name)
            reader.onload = () => {
                if(reader.result) {
                    if (typeof reader.result === "string") {
                        let base64 = reader.result
                        setImageBase64(base64);
                    } else {
                        setImageBase64(null);
                        setFileName(null)
                    }
                }
            }
        } else {
            setImageBase64(null);
            setFileName(null);
            return null;
        }
    }

    const variants = {
        hover: {
            right: '5px',
            //borderColor: 'rgb(7, 43, 87)'

        },
        show: {
            right: '-300px',
            //borderColor: 'rgb(7, 43, 87, 0)'
        }
    }

    const generateDataURL = (base64:string) => {
        if(base64.startsWith('data')) {
            return base64;
        } else {
            const { getImageMime } = require('base64-image-mime')
            let mimeType = getImageMime(base64)
            return 'data:' + mimeType + ';base64,' + base64;
        }
    }

    return (
        <>
            {loading && (
                <div>Loading..</div>
            )}
            {imageBase64 && (
                <motion.div whileHover={'hover'} animate={'show'} style={{position: 'relative', overflow: 'hidden'}}>
                    <Typography variant="body1" fontWeight="bold">
                        {label}
                    </Typography>
                    <img
                        style={{ objectFit: 'contain', width: "100%", height: 200 }}
                        src={generateDataURL(imageBase64)} />
                    <motion.div variants={variants} style={{position: 'absolute', backgroundColor: 'rgba(0,0,0,0.3)', height: '70%', width: '70px', right: '-300px', borderRadius: '10px', top: '15%', display: 'flex', placeContent: 'center', justifyContent: 'end'}}>
                        <Button fullWidth style={{color: 'white'}} onClick={() => {
                            setImageBase64(null);
                            setFile(null);
                            setFileName(null);
                        }}><BiPencil style={{color: 'white', fontSize: '30px'}} ></BiPencil></Button>
                    </motion.div>
                </motion.div>
            )}
            {!imageBase64 && !loading && (
                <>
                    <MuiFileInput
                        value={file}  variant={"outlined"}
                        label={label}
                        name="uploadFile"
                        onChange={(file) => {
                            setFile(file)
                            let base64 = convertToBase64(file);
                            // didChangeImage({
                            //     filename: file?.name,
                            //     fileBase64: base64
                            // })
                        }}
                        error={hasError}
                        helperText={errorText}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                    {initialImageUrl && (
                        <Button style={{float: 'right'}} onClick={() => {
                            setReload(reload+1)
                        }}>Änderungen Rückgängig machen</Button>
                    )}

                </>
            )}

        </>


    )
}