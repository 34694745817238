import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-xhr-backend"; // https://react.i18next.com/latest/using-with-hooks

i18n.use(initReactI18next)
    .use(I18NextHttpBackend)
    .init({
        lng:'de',
        supportedLngs: ["de"],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json" + '?cb=' + (new Date()).getTime(), // locale files path
        },
        defaultNS: "common",
        fallbackLng: ["en", "de"],
    });

export default i18n;