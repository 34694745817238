import React, {useEffect, useLayoutEffect} from "react";
import {Box, Checkbox, Drawer, FormControl, FormGroup, TextField} from "@mui/material";
import {UseModalFormReturnType} from "@refinedev/react-hook-form";
import {HttpError, UpdateResponse, useList, useTranslate} from "@refinedev/core";
import {Create, Edit} from "@refinedev/mui";
import {CheckBoxGroup} from "../../../components/custom/CheckBoxGroup";
import {ICustomerType, IEmployee, IFacility, IInspection, IInspectionItem} from "../../../rest-data-provider/bfaTypes";
import {Control, Controller, FieldValues} from "react-hook-form";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {BfASelect, BfASelectOption} from "../../../components/custom/BfASelect";


interface InspectionItemEditDrawerProps {
     inspection: IInspection | undefined
}
type CombinedProps = UseModalFormReturnType<IInspectionItem, HttpError, IInspectionItem> & InspectionItemEditDrawerProps;
export const InspectionSaveDrawer : React.FC<
    CombinedProps
> = ({ saveButtonProps,
         refineCore: { queryResult, mutationResult, id,onFinish},
         modal: { visible, close},
         register,
         control,
         formState: { errors,},
         setError,
         handleSubmit,
         clearErrors,
         reset, inspection}
) => {


    const translate = useTranslate();
    useLayoutEffect(() => {
        if(mutationResult.error) {
            for (const violation of mutationResult.error.response.data.violations) {
                setError(violation.fieldName,{message: violation.message})
            }
        }
    }, [mutationResult.error])
    useLayoutEffect(() => {
        if(!visible) {
            clearErrors()
            reset({})
        }
        if(visible) {
            reset({});
        }
    },[visible])

    const onFinishHandler = (data: any) => {
        data.inspector = data?.inspector?.id
        return onFinish({
            ...data
        }).then(value => {
            return value
        })
    };
    saveButtonProps.onClick = handleSubmit(onFinishHandler);



    const {isLoading: employeesLoading, data: employeeData} = useList<IEmployee>({
        resource: 'employees',
        pagination: {pageSize: 10000}
    });
    let employees = employeeData?.data

    const formContent = (
        <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            autoComplete="off"
        >
            {id && (
                <TextField
                    {...register("id", {
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth
                    className={'unimportant'}
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate('inspectionItems.fields.id')}
                    name="id"
                    disabled
                />
            )}
            <TextField
                {...register("storey", {
                })}
                error={!!(errors as any)?.storey}
                helperText={(errors as any)?.storey?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('inspectionItems.fields.storey')}
                name="storey"
            />
            <TextField
                {...register("room", {
                })}
                error={!!(errors as any)?.room}
                helperText={(errors as any)?.room?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('inspectionItems.fields.room')}
                name="room"
            />
            <TextField
                {...register("deviceNumber", {
                })}
                error={!!(errors as any)?.deviceNumber}
                helperText={(errors as any)?.deviceNumber?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('inspectionItems.fields.deviceNumber')}
                name="deviceNumber"
            />
            <TextField
                {...register("device", {
                })}
                error={!!(errors as any)?.device}
                helperText={(errors as any)?.device?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('inspectionItems.fields.device')}
                name="device"
            />
            <BfASelect name={'protectionClass'} label={translate('inspectionItems.fields.protectionClass')} control={control as any} options={[
                {label: 'I', selectValue: 'I'}, {label: 'II', selectValue: 'II'}
            ]} />
            <TextField
                {...register("measuringDevice", {
                })}
                error={!!(errors as any)?.measuringDevice}
                helperText={(errors as any)?.measuringDevice?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('inspectionItems.fields.measuringDevice')}
                name="measuringDevice"
            />
            <BfASelect  name={'inspector'} label={translate('inspectionItems.fields.inspector')} control={control as any} options={(() : BfASelectOption[] => {
                if(employees) {
                    return employees.map((value, index) => {
                        return {
                            label: `${value.firstName} ${value.lastName}`,
                            selectValue: value.id,
                            rawValue: value,
                        }
                    });
                }
                return [];
            })()} transform={{
                selectToRaw: selectValue => employees?.find((value) => value.id == +selectValue),
                rawToSelect: rawValue => rawValue?.id
            }} />
            <BfASelect name={'result'} label={translate('inspectionItems.fields.result')} control={control as any} options={[
                {label: 'in Ordnung', selectValue: '1'}, {label: 'nicht in Ordnung', selectValue: '0'}
            ]} transform={{
                selectToRaw: selectValue => selectValue === '1',
                rawToSelect: rawValue => rawValue ? '1'  : rawValue === undefined ? '' : '0'
            }} />
            <TextField
                {...register("action", {
                })}
                error={!!(errors as any)?.action}
                helperText={(errors as any)?.action?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('inspectionItems.fields.action')}
                name="device"
            />
            <TextField
                {...register("recommendation", {
                })}
                error={!!(errors as any)?.recommendation}
                helperText={(errors as any)?.recommendation?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('inspectionItems.fields.recommendation')}
                name="device"
            />
            <TextField
                {...register("comment", {
                })}
                error={!!(errors as any)?.comment}
                helperText={(errors as any)?.comment?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate('inspectionItems.fields.comment')}
                name="comment"
            />
        </Box>)
    return(
        <Drawer
            open={visible}
            onClose={close}
            anchor="right"
            PaperProps={{sx: { width: { sm: "100%", md: 500 }, } }}
            disableScrollLock={false}


        >
            {id && (
                <Edit wrapperProps={{style: {overflowY: 'scroll'}}} title={translate('inspectionItems.titles.edit')} deleteButtonProps={{
                    errorNotification: error => BfARefineUtils.generateDeleteErrorNotification(translate('inspectionItems.inspectionItems')),
                    successNotification: data => BfARefineUtils.generateDeleteSuccesNotification(translate('inspectionItems.inspectionItems')),
                    resource:`inspections/${inspection?.id}/items`,
                    onSuccess: value => {
                        setTimeout(() => {
                            close();
                        }, 500)
                    }
                    }}
                      recordItemId={id} breadcrumb={false} goBack={false} canDelete={true} resource={`inspections/${inspection?.id}/items`} saveButtonProps={saveButtonProps}>
                    {formContent}
                </Edit>
            )}
            {!id && (
                <Create wrapperProps={{style: {overflowY: 'scroll'}}} title={translate('inspectionItems.titles.create')} breadcrumb={false} goBack={false} resource={`inspections/${inspection?.id}/items`}  saveButtonProps={saveButtonProps}>
                    {formContent}
                </Create>
            )}

        </Drawer>
    )
}