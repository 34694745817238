import { Create, Edit } from "@refinedev/mui";
import {
    Box,
    Button,
    Checkbox, FormControl,
    FormControlLabel, FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import React, {useState} from "react";
import {Controller, FieldValues} from "react-hook-form";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {useList, useTranslate} from "@refinedev/core";
import {IEmployee, IInspection} from "../../../rest-data-provider/bfaTypes";
import BfADatePicker from "../../../components/custom/BfADatePicker";
import {BfASelect, BfASelectOption} from "../../../components/custom/BfASelect";

export const InspectionsCreateEdit = () => {
    const {
        saveButtonProps,
        refineCore: { formLoading, queryResult, onFinish },
        register,
        control,
        formState: { errors },
        setError,
        getValues,
        handleSubmit
    } = useForm<IInspection>(
        {
            refineCoreProps: {
                onMutationError: (error) => {
                    BfARefineUtils.extractServerSideErrors(error, setError);
                },
            },

        });
    const translate = useTranslate();
    const inspection = queryResult?.data?.data;


    const onFinishHandler = (data: FieldValues) => {
        data.leader = data.leader.id
        data.didCreateReport = data.didCreateReport == 'true' ? true : false
        onFinish({
            ...data,
        });
    };
    saveButtonProps.onClick = handleSubmit(onFinishHandler);

    const {isLoading: employeesLoading, data: employeeData} = useList<IEmployee>({
        resource: 'employees',
        pagination: {pageSize: 10000}
    });
    const employees = employeeData?.data




    const generateFormFields = (edit: boolean) => {
        return (
            <Box
                component="form"
                // sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Grid container columnSpacing={2} rowSpacing={0}>
                    {queryResult?.data?.data.id && (
                        <Grid item xs={1}>
                            <TextField
                                className={'unimportant'}
                                {...register("id", {})}
                                error={!!(errors as any)?.id}
                                helperText={(errors as any)?.id?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate('inspections.fields.id')}
                                name="id"
                                disabled
                            />
                        </Grid>
                    )}

                    <Grid item xs={inspection?.id ? 5 : 6}>
                        <TextField className={'unimportant'} margin={"normal"} fullWidth disabled={true} label={translate('inspections.fields.customer')}
                                   defaultValue={" "} value={inspection?.customer?.name} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={'unimportant'} margin={"normal"} fullWidth disabled={true} label={translate('inspections.fields.facility')}
                                   defaultValue={" "} value={ `${inspection?.facility?.name} - ${inspection?.facility.street} ${inspection?.facility.houseNumber}, ${inspection?.facility.zipCode} ${inspection?.facility.city}`} />
                    </Grid>

                    <Grid item xs={4}>
                        <BfADatePicker name={'begin'} label={translate('inspections.fields.begin')} control={control}></BfADatePicker>
                    </Grid>
                    <Grid item xs={4}>
                        <BfADatePicker name={'end'} label={translate('inspections.fields.end')} control={control}></BfADatePicker>
                    </Grid>
                    <Grid item xs={4}>
                        {
                           (
                                <BfASelect  name={'leader'} label={translate('inspections.fields.leader')} control={control} options={(() : BfASelectOption[] => {
                                    if(employees) {
                                        return employees.map((value, index) => {
                                            return {
                                                label: `${value.firstName} ${value.lastName}`,
                                                selectValue: value.id,
                                                rawValue: value,
                                            }
                                        });
                                    }
                                    return [];
                                })()} transform={{
                                    selectToRaw: selectValue => employees?.find((value) => value.id == +selectValue),
                                    rawToSelect: rawValue => rawValue?.id
                                }} />
                            )
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <BfASelect name={'deadline'} label={translate('inspections.fields.deadline')} control={control} options={(() : BfASelectOption[] => {
                            const intervals = ['3', '6', '9', '12', '18', '24']
                            return intervals.map((value, index) => {
                               return {
                                   label: `${value} Monate`,
                                   selectValue: value,
                               }
                            });
                        })()} />
                    </Grid>
                    <Grid item xs={4}>
                        <BfASelect name={'didCreateReport'} label={translate('inspections.fields.didCreateReport')} control={control} options={(() : BfASelectOption[] => {
                            const intervals = ["true", "false"];
                            return intervals.map((value, index) => {
                                return {
                                    label: value == 'true' ? 'Ja' : 'Nein',
                                    selectValue: value,
                                }
                            });
                        })()} />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <>
            {queryResult?.data?.data.id && (
                <Edit isLoading={formLoading && employeesLoading} saveButtonProps={saveButtonProps} >
                    {generateFormFields(true)}
                </Edit>
            )}
            {!queryResult?.data?.data.id && (
                <Create isLoading={formLoading && employeesLoading} saveButtonProps={saveButtonProps} >
                    {generateFormFields(false)}
                </Create>

            )}
        </>
    );
};
