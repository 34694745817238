import {IResourceComponentsProps, useTranslate} from "@refinedev/core";
import {useDataGrid} from "@refinedev/mui";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import React from "react";
import {GridColDef} from "@mui/x-data-grid";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";
import {Chip, Stack, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import {ICustomerType, IFacility} from "../../../rest-data-provider/bfaTypes";

interface FacilityListProps {
    customer?: ICustomerType,
    editFacility: (facility : IFacility) => void,
    createFacility: () => void,
    customerId?: string
}

type CombinedProps = IResourceComponentsProps<IFacility> & FacilityListProps;

export const FacilityListV3 : React.FC<CombinedProps> = ({customer, editFacility, createFacility, customerId}) => {
    const translate = useTranslate();
    const { dataGridProps, search } = useDataGrid(
        {
            onSearch: BfARefineUtils.dataGridSearch,
            resource:`customers/${customerId}/facilities`,
            pagination: {pageSize: 100}
        });

    const columns = React.useMemo<GridColDef<IFacility>[]>(
        () => [
            // {
            //     field: "id",
            //     headerName: translate("customers.fields.id"),
            //     type: "number",
            //     minWidth: 50,
            // },
            {
                field: "name",
                headerName: translate("facilities.fields.name"),
                minWidth: 400,
                renderCell: params => {
                    return (
                        <>
                            {!params.value && (
                                <Typography color='grey' variant={'body2'}>{customer?.name}</Typography>
                            )}
                            {params.value && (
                                <Typography variant={'body2'}>{params.value}</Typography>
                            )}
                        </>
                    )
                }
            },
            {
                field: "zipCode",
                headerName: translate("facilities.fields.address"),
                minWidth: 300,
                // valueGetter: params => {
                //     return `${params.row.street} ${params.row.houseNumber}`;
                // },
                renderCell: params => {
                    return (
                        <div style={{margin: '5px'}}>
                            <Typography variant={'body2'}>{`${params.row.street} ${params.row.houseNumber}`}</Typography>
                            <Typography variant={'body2'}>{`${params.row.zipCode} ${params.row.city}`}</Typography>
                        </div>
                    )
                }
            },
            {
                field: "facilityTypes",
                headerName: translate("facilities.fields.types"),
                minWidth: 300,
                flex:1,
                // valueGetter: params => {
                //     return `${params.row.street} ${params.row.houseNumber}`;
                // },
                renderCell: params => {
                    return (
                        <Stack style={{margin: '5px'}} direction={'row'} gap={1} useFlexGap flexWrap="wrap">
                            {params.row.facilityTypes.length > 0 && params.row.facilityTypes.map(facilityType => {
                                return (
                                    <Chip style={{backgroundColor: uniqolor(facilityType.name, {lightness: 85}).color}} size={'small'} key={facilityType.id} label={facilityType.name}></Chip>
                                )
                            })}
                            {params.row.facilityTypes.length === 0 && (
                                <Typography color={'grey'}>keine</Typography>
                            )}
                        </Stack>
                    )
                }
            },
        ],
        [translate, customer],
    );
    return (
        <BfADataGrid title={translate('facilities.facilities')} onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
            editFacility(params.row);
        }} onCreate={() => {
            createFacility();
        }} columns={columns} />
    );
}