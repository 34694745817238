import { AuthPage } from "@refinedev/mui";

export const Login = () => {
  return (
    <AuthPage
      type="login"
      registerLink={<></>}
      rememberMe={false}
      forgotPasswordLink={<></>}
      title={
        <div>
            <img style={{maxWidth: '250px'}} src={'/img/logo.png'}></img>
        </div>
      }
      // formProps={{
      //   defaultValues: { email: "demo@refine.dev", password: "demodemo" },
      // }}
    />
  );
};
