import {IResourceComponentsProps, useShow, useTranslate} from "@refinedev/core";
import {Show} from "@refinedev/mui";
import {Stack, TextField, Typography} from "@mui/material";
import {IMeasuringDeviceType} from "../../../rest-data-provider/bfaTypes";
import "../../../utils/BfADisabledInputs.css";

export const BlogPostShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IMeasuringDeviceType>();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
      <Show isLoading={isLoading}>
        <Stack gap={2}>
          <TextField disabled={true} variant={"outlined"} label={translate('measuringdevices.fields.id')} defaultValue={" "} value={record?.id} />
          <TextField disabled={true} label={translate('measuringdevices.fields.name')} defaultValue={" "} value={record?.name} />
          <TextField disabled={true} label={translate('measuringdevices.fields.serialNumber')} defaultValue={" "} value={record?.serialNumber} />

          <TextField className={'unimportant'} disabled={true} label={translate('measuringdevices.fields.createdAt')} defaultValue={" "} value={record?.createdAt ? new Date(record!.createdAt).toLocaleString() : '-'} />
          <TextField className={'unimportant'} disabled={true} label={translate('measuringdevices.fields.updatedAt')} defaultValue={" "} value={record?.updatedAt ? new Date(record!.updatedAt).toLocaleString() : '-'} />
        </Stack>
      </Show>
  );
};
