import {IResourceComponentsProps, useShow, useTranslate} from "@refinedev/core";
import {IDevice, IMeasuringDeviceType, INote} from "../../../rest-data-provider/bfaTypes";
import {Show, TagField} from "@refinedev/mui";
import {Chip, Grid, Stack, TextField, Typography} from "@mui/material";
import uniqolor from "uniqolor";
import React from "react";

export const NoteShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow<INote>();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={2}>
                <TextField className={'unimportant'}  disabled={true} variant={"outlined"} label={translate('notes.fields.id')} defaultValue={" "} value={record?.id} />
                <TextField disabled={true} label={translate('notes.fields.name')} defaultValue={" "} value={record?.name} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={true} label={translate('notes.fields.category')} defaultValue={" "} value={record?.category} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField fullWidth className={'unimportant'} disabled={true} label={translate('notes.fields.createdAt')} defaultValue={" "} value={record?.createdAt ? new Date(record!.createdAt).toLocaleString() : '-'} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth className={'unimportant'} disabled={true} label={translate('notes.fields.updatedAt')} defaultValue={" "} value={record?.updatedAt ? new Date(record!.updatedAt).toLocaleString() : '-'} />
                    </Grid>
                </Grid>
            </Stack>
        </Show>
    );
};
