import {useNavigation, useTranslate} from "@refinedev/core";

import React from "react";
import {useDataGrid,} from "@refinedev/mui";
import {GridColDef} from "@mui/x-data-grid";
import {DateField} from "../../../components/fields/date";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";

export const NotesList = () => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const { dataGridProps, search } = useDataGrid(
        {
            onSearch: BfARefineUtils.dataGridSearch,
            pagination: {pageSize: 100}
        });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("notes.fields.id"),
                type: "number",
                minWidth: 50,
                align: 'center',
                headerAlign: 'center'
            },
            {
                field: "name",
                headerName: translate("notes.fields.name"),
                flex: 1,
                minWidth: 300,
            },
            {
                field: "category",
                headerName: translate("notes.fields.category"),
                align: 'center',
                headerAlign: 'center',
                minWidth: 140,
            },
            {
                field: "createdAt",
                // flex: 1,
                headerName: translate("measuringdevices.fields.createdAt"),
                minWidth: 150,
                align: 'center',
                headerAlign: 'center',
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "updatedAt",
                // flex: 1,
                headerName: translate("measuringdevices.fields.updatedAt"),
                minWidth: 150,
                align: 'center',
                headerAlign: 'center',
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            }
        ],
        [translate],
    );

    return (
        <BfADataGrid onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
            show('notes', params.id)
        }} columns={columns} />
    );
};
