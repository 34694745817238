import { AuthBindings } from "@refinedev/core";
import axios from "axios";
import {axiosInstance} from "./rest-data-provider/utils";
import * as process from "process";
import {AuthActionResponse} from "@refinedev/core/dist/contexts/auth/types";

export const TOKEN_KEY = "refine-auth";

// axiosInstance.interceptors.response.use(response => {
//   return response;
// }, error => {
//   if (error.response.status === 401 && !window.location.pathname.endsWith('/login')) {
//     localStorage.removeItem(TOKEN_KEY)
//     //window.location.href = '/login';
//   }
//   return error;
// });

export const authProvider: AuthBindings = {
  login: async ({ username, email, password }):Promise<AuthActionResponse> => {
    localStorage.removeItem(TOKEN_KEY);
    axiosInstance.defaults.headers.common = {};
    return await axiosInstance.post(`${import.meta.env.VITE_API_BASE_URL}/admins/login`, { username: email, password: password })
      .then(value =>
        {
          localStorage.setItem(TOKEN_KEY, value.data.token);
          axiosInstance.defaults.headers.common = {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          };
          return {
            success: true,
            redirectTo: "/",
          };
        }).catch(reason => {
          return {
            success: false,
            error: {
              name: "LoginError",
              message: "Invalid username or password",
            },
          };
      });
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    delete axiosInstance.defaults.headers.common['Authorization'];
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        axiosInstance.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
      return await axiosInstance.get(`${import.meta.env.VITE_API_BASE_URL}/admins/aboutme`)
          .then(value => {

            return {
              authenticated: true,
            };
          }).catch(reason => {
            return {
              authenticated: false,
              redirectTo: "/login",
            };
          });
    }
    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return await axiosInstance.get(`${import.meta.env.VITE_API_BASE_URL}/admins/aboutme`)
        .then(value => {
          console.log(value.data)
          return {
            id: 1,
            name: `${value.data?.firstName} ${value.data?.lastName}` ,
            avatar:`${value.data?.firstName?.charAt(0)} ${value.data?.lastName?.charAt(0)}`
          };
        });
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
