import React from "react";
import {IResourceComponentsProps, useNavigation, useTranslate} from "@refinedev/core";
import {useDataGrid} from "@refinedev/mui";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {GridColDef} from "@mui/x-data-grid";
import {DateField} from "../../../components/fields/date";
import {BfADataGrid} from "../../../components/custom/BfADataGrid";

export const DeviceCategoryList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { show } = useNavigation();
    const { dataGridProps, search } = useDataGrid(
        {
            onSearch: BfARefineUtils.dataGridSearch,
            pagination: {pageSize: 100}
        });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("customers.fields.id"),
                type: "number",
                minWidth: 50,

            },
            {
                field: "name",
                flex: 1,
                headerName: translate("customers.fields.name"),
                minWidth: 550,
            },
            {
                field: "createdAt",
                // flex: 1,
                headerName: translate("customers.fields.createdAt"),
                minWidth: 200,
                maxWidth: 300,
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            {
                field: "updatedAt",
                // flex: 1,
                headerName: translate("customers.fields.updatedAt"),
                minWidth: 200,
                maxWidth: 300,
                renderCell: function render({ value }) {
                    return <DateField value={value} />;
                },
            },
            // {
            //     field: "actions",
            //     headerName: translate("table.actions"),
            //     sortable: false,
            //     renderCell: function render({ row }) {
            //         return (
            //             <>
            //                 <EditButton hideText recordItemId={row.id} />
            //                 <ShowButton hideText recordItemId={row.id} />
            //             </>
            //         );
            //     },
            //     align: "center",
            //     headerAlign: "center",
            //     minWidth: 80,
            // },
        ],
        [translate],
    );

    return (
        <BfADataGrid showSearch={false} onSearchTermChanged={search} dataGridProps={dataGridProps} onRowClick={params => {
            show('device-categories', params.id)
        }} columns={columns} />
    );
};
