import {useForm} from "@refinedev/react-hook-form";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";
import {useTranslate} from "@refinedev/core";
import {Edit} from "@refinedev/mui";
import {Box, TextField} from "@mui/material";
import {IDeviceCategory} from "../../../rest-data-provider/bfaTypes";

export const DeviceCategoryEdit: React.FC = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
        setError
    } = useForm<IDeviceCategory>({
        refineCoreProps: {
            onMutationError: error => {
                BfARefineUtils.extractServerSideErrors(error, setError);
            },
            redirect: "show",
        }
    });
    const translate = useTranslate();


    return (
        <>
            <Edit saveButtonProps={saveButtonProps}>
                <Box
                    component="form"
                    sx={{ display: "flex", flexDirection: "column" }}
                    autoComplete="off"
                >
                    <TextField
                        {...register("id", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.id}
                        helperText={(errors as any)?.id?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        label={translate('measuringdevices.fields.id')}
                        name="id"
                        disabled
                    />
                    <TextField
                        {...register("name")}
                        error={!!(errors as any)?.name}
                        helperText={(errors as any)?.name?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate('measuringdevices.fields.name')}
                        name="name"
                    />
                </Box>
            </Edit>
        </>
    )
};