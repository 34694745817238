import {useForm} from "@refinedev/react-hook-form";
import {ICustomerType} from "../../../rest-data-provider/bfaTypes";
import {useParams} from "react-router-dom";
import {Create, Edit} from "@refinedev/mui";
import {Box, TextField} from "@mui/material";
import {BfARefineUtils} from "../../../utils/BfARefineUtils";

export const CustomerCreate = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult , formLoading},
        register,
        control,
        formState: { errors },
        setError
    } = useForm<ICustomerType>({
        refineCoreProps: {
            onMutationError: error => {
                BfARefineUtils.extractServerSideErrors(error, setError);
            },
        }
    });

    return (
        <>
            <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
                <Box
                    component="form"
                    sx={{ display: "flex", flexDirection: "column" }}
                    autoComplete="off"
                >
                    <TextField
                        {...register("name")}
                        error={!!(errors as any)?.name}
                        helperText={(errors as any)?.name?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Name"
                        name="name"
                    />
                </Box>
            </Create>
        </>

    );
};