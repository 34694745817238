import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid} from "@mui/material";
import {IBfABaseType} from "../../rest-data-provider/bfaTypes";
import {useEffect, useState} from "react";
import {Control, Controller} from "react-hook-form";


interface CheckBoxGroupProps {
    title: string
    options:Array<IBfABaseType>|undefined
    selectedOptions: Array<IBfABaseType>|undefined
    onChange: (selectedOptions: Array<IBfABaseType>) => void
}

export const CheckBoxGroup : React.FC<CheckBoxGroupProps> = ({title, options, selectedOptions, onChange}) => {

    const [selection, setSelection] = useState<Array<IBfABaseType>|undefined>(undefined);

    useEffect(() => {
        if(selectedOptions) {
            setSelection(selectedOptions);
        } else {
            setSelection([]);
        }

    }, [selectedOptions])

    const isSelected = (facilityType : IBfABaseType) => {
        if(selection) {
            return selection?.some(value => {
                return value.id === facilityType.id
            })
        }
        return false;
    }

    return (
                <FormControl
                    error={false}
                    component="fieldset"
                    sx={{ m: 2 }}
                    variant="standard"
                >
                    <FormLabel component="legend">{title}</FormLabel>
                    <FormGroup>
                        <Grid container>
                            {options?.map((value, index) => {
                                return (
                                    <Grid item xs={6} key={value.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox name={value.name} checked={isSelected(value)} onChange={(evt) => {
                                                    let newSelection : any;
                                                    if(evt.target.checked) {
                                                        console.log('hinzu')
                                                        newSelection = (selection?.concat(value));
                                                        setSelection(newSelection);
                                                    }
                                                    if(!evt.target.checked) {
                                                        console.log('raus')
                                                        newSelection = selection?.filter((e, i) => {
                                                            return e.id !== value.id;
                                                        })

                                                        setSelection(newSelection);
                                                    }
                                                    console.log(newSelection)
                                                    onChange(newSelection ? newSelection : []);
                                                }} />
                                            }
                                            label={value.name}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </FormGroup>
                </FormControl>
    )
}