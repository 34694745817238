import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Control, Controller} from "react-hook-form";
import React from "react";
import {useTranslate} from "@refinedev/core";



export interface BfASelectOption {
    selectValue: string|number,
    label: string,
    rawValue?: any,
}
export interface BfASelectProps {
    name: string;
    label: string;
    control: Control;
    defaultValue?: any;
    options: BfASelectOption[],
    transform?: {
        rawToSelect: (rawValue?: any) => string,
        selectToRaw: (selectValue: string) => any
    }
    // mode: 'edit'|'create'
}

export const BfASelect:React.FC<BfASelectProps> = ({options, control, defaultValue, name, label, transform}) => {
    const translate = useTranslate();
    const cleanValue = (value:any):any => {
        if(value === null || value == undefined) {
            return "";
        }
        return value;
    }
    return (
        <Controller
            control={control}
            render={({
                         field,
                         fieldState: { invalid, isTouched, isDirty, error },
                         formState,
                     }) => {
               return (
                    <>
                        <FormControl sx={{ fieldset: { legend: {maxWidth: '100%'}} }} style={{marginTop: '15px'}} fullWidth>
                            <InputLabel error={!!(error)} id="demo-simple-select-label" shrink={true}>{label}</InputLabel>
                            {/*{`${mode} ${field} ${field.value}`}*/}
                            {/*{(mode != "edit" ||( mode == "edit" && field)) && (*/}
                                <Select
                                    {...field}
                                    onChange={(event, child) => {
                                        if(transform) {
                                            let transformedValue = transform.selectToRaw(event.target.value);
                                            field.onChange(transformedValue);
                                        } else {
                                            field.onChange(event, child)
                                        }
                                    }}
                                    value={cleanValue(transform ? transform.rawToSelect(field?.value) : field?.value)}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={label}
                                    error={!!error}
                                >
                                    {options.map((value, index) => {
                                        return (
                                            <MenuItem key={index} value={value.selectValue}>{value.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            {/*)}*/}
                            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
                        </FormControl>
                    </>
                )}} name={name} />

    )
}